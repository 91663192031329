import Container from '../component/container';
import Layout from '../component/layout';

export default function NotFound() {
  return (
    <Layout>
      <Container>
        <h1 className="text-center text-3xl">404 page</h1>
      </Container>
    </Layout>
  );
}
