import Heading from '../component/atom/heading';
import Container from '../component/container';
import Layout from '../component/layout';
import FindFriendsSearchForm from '../component/organisms/find-friends-search-form';
import Hero from '../component/organisms/hero';

export default function Home() {
  return (
    <Layout>
      <Hero />
      <div className="py-10 lg:py-20">
        <Container>
          <Heading text="Find Your Friends" className="!text-left !mb-8 lg:!mb-12" />
        </Container>
        <FindFriendsSearchForm />
      </div>
    </Layout>
  );
}
