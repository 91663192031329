import { Fragment, useState } from 'react';
import cn from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { DownArrow } from '../atom/icons/down-arrow';
import { Tick } from '../atom/icons/tick';
import { IListBoxOption } from '../../type';
import { Input } from './input';

interface IProps {
  options: IListBoxOption[];
  className?: string;
  buttonClassName?: string;
  placeholder: string;
  optionSearch?: boolean;
  optionSearchInputWrapperClassName?: string;
  optionSearchInputClassName?: string;
  optionSearchInputPlaceholder?: string;
  onChange?: (v: string) => void;
  selected: IListBoxOption | null;
  setSelected: (v: IListBoxOption) => void;
}

export default function ListBox({
  options,
  className,
  buttonClassName,
  placeholder,
  optionSearch = false,
  optionSearchInputWrapperClassName,
  optionSearchInputClassName,
  optionSearchInputPlaceholder,
  selected,
  setSelected,
  onChange,
}: IProps) {
  const [searchValue, setSearchValue] = useState<string>('');

  function handleChange(v: IListBoxOption) {
    setSelected(v);
    onChange && onChange(v.value);
  }

  return (
    <div role="button" className={cn(className)}>
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className={cn(
                'relative w-full h-10 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none outline-0 focus:outline-0 focus-visible:outline-0  border transition-all',
                { 'border-brand z-20': open },
                buttonClassName
              )}
            >
              <span className="block truncate ">{selected ? selected.name : <span className="text-gray-400">{placeholder}</span>}</span>
              <span className={cn('pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2')}>
                <DownArrow
                  className={cn('transition-all', {
                    'rotate-180': open,
                  })}
                />
              </span>
            </Listbox.Button>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                {!!optionSearch && (
                  <div className={cn('p-2', optionSearchInputWrapperClassName)}>
                    <Input
                      value={searchValue}
                      onChange={(value) => setSearchValue(value)}
                      className={cn(optionSearchInputClassName)}
                      placeholder={optionSearchInputPlaceholder}
                    />
                  </div>
                )}
                <div className="max-h-60 overflow-auto">
                  {(searchValue
                    ? options.filter((item: IListBoxOption) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
                    : options
                  )
                    .sort(function (a, b) {
                      var nameA = a.name.toUpperCase(); // convert to uppercase to ensure case-insensitive sorting
                      var nameB = b.name.toUpperCase(); // convert to uppercase to ensure case-insensitive sorting
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      // names must be equal
                      return 0;
                    })
                    .map((person: IListBoxOption, personIdx: number) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-brand/20' : 'text-gray-900'}`
                        }
                        value={person}
                      >
                        {({ selected }) => (
                          <>
                            <span className={`block truncate ${selected ? 'font-medium text-brand' : 'font-normal'}`}>{person.name}</span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand">
                                <Tick />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
