import createBreakpoint from 'react-use/lib/factory/createBreakpoint';

const breakPoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const useBreakpoint = createBreakpoint(breakPoints);
