export interface ISingleData {
  id: number;
  name: string;
  shift: 'Morning' | 'Day';
  phoneNumber: number | string;
  university?: {
    name?: string;
    department?: string;
  };
  bloodGroup: 'A_positive' | 'A_negative' | 'B_positive' | 'B_negative' | 'AB_positive' | 'AB_negative' | 'O_positive' | 'O_negative';
  occupation: string;
  dateOfBirth: string;
  address: {
    present: string;
    permanent: string;
  };
  image: string;
  links?: {
    facebook?: string;
    linkedin?: string;
  };
}

export const allData: ISingleData[] = [
  {
    name: 'Ashikur Rahman Asif',
    id: 1,
    shift: 'Morning',
    phoneNumber: '01641145180',
    university: {
      name: 'Institute of Science Trade & Technology (ISTT)',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-01-14',
    address: {
      present: 'Mirpur-13,Dhaka',
      permanent: 'Station Para, Jamalpur-2000',
    },
    image: '/assets/avatars/ashikur-rahman-asif__morning__01641145180.jpg',
    links: {
      facebook: 'https://facebook.com/ashikurrahman1s9f',
      linkedin: 'https://www.linkedin.com/in/ashikur-rahman-asif/',
    },
  },
  {
    name: 'Kazi Mofasserul Islam Orun',
    id: 2,
    shift: 'Morning',
    phoneNumber: '01798081381',
    university: {
      name: 'Brac University ',
      department: 'EEE',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '1997-10-08',
    address: {
      present: 'Mohakhali,Dhaka',
      permanent: 'Uttor Shirajabad, Islampur, Jamalpur ',
    },
    image: '/assets/avatars/kazi-mofasserul-islam-orun__morning__01798081381.jpg',
    links: {
      facebook: 'https://www.facebook.com/kazi.orun?mibextid=ZbWKwL',
    },
  },
  {
    name: 'MD. SAIFULLAH SAYEM FAHIM',
    id: 3,
    shift: 'Morning',
    phoneNumber: '01755984021',
    university: {
      name: 'Habibullah Bahar University College',
      department: 'Economics',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1998-02-05',
    address: {
      present: 'HASNABAD, KERANIGANJ, DHAKA',
      permanent: 'DEWANGANJ, JAMALPUR',
    },
    image: '/assets/avatars/md-saifullah-sayem-fahim__morning__01798081381.jpg',
    links: {
      facebook: 'https://www.facebook.com/fahimssf?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Nur A Nazil',
    id: 4,
    shift: 'Morning',
    phoneNumber: '01987887986',
    university: {
      name: 'Stamford University Bangladesh',
      department: 'Civil Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-05',
    address: {
      present: 'Dhaka, Bangladesh ',
      permanent: 'JAMALPUR',
    },
    image: '/assets/avatars/nur-a-nazil__morning__01987887986.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100007346043392&mibextid=ZbWKwL',
    },
  },
  {
    name: 'MAHEDY HASAN',
    id: 5,
    shift: 'Morning',
    phoneNumber: '01787777404',
    university: {
      name: 'Hajee Mohammad Danesh Science & Technology University (HSTU)',
      department: 'Fisheries ',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-12-30',
    address: {
      present: 'Dinajpur, Bangladesh ',
      permanent: 'JAMALPUR',
    },
    image: '/assets/avatars/mahedy-hasan__morning__01787777404.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100007410671049',
    },
  },
  {
    name: 'Sharaf Anjum Nowki ',
    id: 6,
    shift: 'Morning',
    phoneNumber: '01777515485',
    university: {
      name: 'North South university (NSU)',
      department: 'BBA ',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2001-06-24',
    address: {
      present: 'Bashundhara R/A',
      permanent: 'West nayapara, Jamalpur',
    },
    image: '/assets/avatars/sharaf-anjum-nowki__morning__01777515485.jpg',
    links: {
      facebook: 'https://www.facebook.com/sharaf.nowki?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md. Anwar Sadat (Lubon)',
    id: 7,
    shift: 'Morning',
    phoneNumber: '01959324363',
    university: {
      name: 'Jahangirnagar University (JU)',
      department: 'English',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-06-02',
    address: {
      present: 'Jahangirnagar University, Savar',
      permanent: 'Village: Sreepur Kumaria, Sadar, Jamalpur',
    },
    image: '/assets/avatars/md-anwar-sadat-lubon__morning__01959324363.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100088427196552&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Sajjad Hossain Onik',
    id: 8,
    shift: 'Morning',
    phoneNumber: '01322146242',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1998-10-03',
    address: {
      present: 'Bashundhara r/a',
      permanent: 'Jamalpur, pathalia,Jamalpur ',
    },
    image: '/assets/avatars/sajjad-hossain-onik__morning__01322146242.jpg',
    links: {
      facebook: 'https://www.facebook.com/onik9090?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Sadab BIn Rafiq Dhrubo',
    id: 9,
    shift: 'Morning',
    phoneNumber: '01733056599',
    university: {
      name: 'Graphic Arts Institute',
      department: 'Graphic Design',
    },
    bloodGroup: 'A_positive',
    occupation: 'Software Engineer',
    dateOfBirth: '2001-08-24',
    address: {
      present: 'West Dhanmondi Residential Area, Bosila, Mohammadpur, Dhaka',
      permanent: '580/1 New College Road, Jamalpur',
    },
    image: '/assets/avatars/sadab-bin-rafiq-dhrubo__morning__01733056599.jpg',
    links: {
      facebook: 'https://www.facebook.com/sadabdhrubo',
      linkedin: 'https://www.linkedin.com/in/sadabbinrafiqdhrubo/',
    },
  },
  {
    name: 'Aktaruzzaman Razib',
    id: 10,
    shift: 'Morning',
    phoneNumber: '01733779547',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Political Science',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-10-29',
    address: {
      present: 'Dhanmondi, Dhaka',
      permanent: 'Doyamyi,Jamalpur',
    },
    image: '/assets/avatars/aktaruzzaman-razib__morning__01733779547.jpg',
    links: {
      facebook: 'https://facebook.com/aktaruzzaman.razib.7/',
    },
  },
  {
    name: 'Md. Kamrul Islam Manik',
    id: 11,
    shift: 'Morning',
    phoneNumber: '01631809892',
    university: {
      name: 'University of Asia Pacific (UAP)',
      department: 'Law & HR',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-11-30',
    address: {
      present: 'Dhanmondi15,Dhaka',
      permanent: 'Sharishabari,Jamalpur',
    },
    image: '/assets/avatars/md-kamrul-islam-manik__morning__01631809892.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100009585219003',
    },
  },
  {
    name: 'Ahsanul Haque Jibon',
    id: 12,
    shift: 'Morning',
    phoneNumber: '01721556199',
    university: {
      name: 'Independent University Bangladesh (IUB)',
      department: 'LLB',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-03-09',
    address: {
      present: 'Bashundhara R/A',
      permanent: 'Dewanpara ,Jamalpur',
    },
    image: '/assets/avatars/ahsanul-haque-jibon__morning__01721556199.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100009585219003',
    },
  },
  {
    name: 'Niloy',
    id: 13,
    shift: 'Morning',
    phoneNumber: '01834728015',
    university: {
      name: 'Khulna University of Engineering & Technology (KUET)',
      department: 'BME',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-02-01',
    address: {
      present: 'Khanbari Road, West Fulbaria, Jamalpur Sadar, Jamalpur',
      permanent: 'Khanbari Road, West Fulbaria, Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/niloy__morning__01834728015.jpg',
    links: {
      facebook: 'https://www.facebook.com/iamniloymahmud',
    },
  },
  {
    name: 'Md. Tasnif Rahman',
    id: 14,
    shift: 'Morning',
    phoneNumber: '01771446899',
    university: {
      name: 'Patuakhali Science and Technology University (PSTU)',
      department: 'CSE',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-07-01',
    address: {
      present: 'Jamalpur Sadar, Jamalpur ',
      permanent: 'Jamalpur Sadar, Jamalpur ',
    },
    image: '/assets/avatars/md-tasnif-Rahman__morning__01771446899.jpg',
    links: {
      facebook: 'https://www.facebook.com/tasnif.simanto?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Ahmed Ahsan Shimanta',
    id: 15,
    shift: 'Morning',
    phoneNumber: '01937640549',
    university: {
      name: 'Bangladesh University of Textiles (BUTEX)',
      department: 'Textile Chemical Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-08-20',
    address: {
      present: 'GMAG Osmani Hall',
      permanent: 'Pathalia, Jamalpur',
    },
    image: '/assets/avatars/ahmed-ahsan-shimanta__morning__01937640549.jpg',
    links: {
      facebook: 'https://www.facebook.com/ahmedshimanta?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Shekh Sadi Ruhan',
    id: 16,
    shift: 'Morning',
    phoneNumber: '01937640549',
    university: {
      name: 'Sylhet Polytechnic Institute (SPI)',
      department: 'EEE',
    },
    bloodGroup: 'A_negative',
    occupation: 'Sub-Assisstant Engineer',
    dateOfBirth: '2000-03-21',
    address: {
      present: 'Jamalpur Sadar',
      permanent: 'Jamalpur Sadar',
    },
    image: '/assets/avatars/shekh-sadi-ruhan__morning__01752323781.jpg',
    links: {
      facebook: 'https://www.facebook.com/shekhsaadi.ruhan.99?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md.Tasnimul Karim',
    id: 17,
    shift: 'Morning',
    phoneNumber: '01960149479',
    university: {
      name: 'Bangladesh University of Textiles (BUTEX)',
      department: 'Fabric Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-12-23',
    address: {
      present: 'Love road, Tejgaon',
      permanent: 'Dewanpara, Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/md-tasnimul-karim__morning__01752323781.jpg',
    links: {
      facebook: 'https://www.facebook.com/tasnimul.karim.31?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md Oishorjo Adnan',
    id: 18,
    shift: 'Morning',
    phoneNumber: '01941245129',
    university: {
      name: 'Green University (GU)',
      department: 'Textile Engineering ',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-01',
    address: {
      present: 'Bonorsee',
      permanent: 'Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/md-oishorjo-adnan__morning__01941245129.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100070905641010&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md Tahsin Habib',
    id: 19,
    shift: 'Morning',
    phoneNumber: '01745085051',
    university: {
      name: 'Khulna University of Engineering & Technology (KUET)',
      department: 'Leather Engineering',
    },
    bloodGroup: 'B_negative',
    occupation: 'Student',
    dateOfBirth: '1998-08-18',
    address: {
      present: 'khulna',
      permanent: 'Dewanpara, Jamalpur',
    },
    image: '/assets/avatars/md-tahsin-habib__morning__01745085051.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100005582955650&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Shanto',
    id: 20,
    shift: 'Morning',
    phoneNumber: '01734473956',
    university: {
      name: 'Sylhet Agricultural University (SAU)',
      department: 'Doctor of veterinary medicine',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '1999-03-19',
    address: {
      present: 'Sylhet',
      permanent: 'Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/shanto__morning__01734473956.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100011729486371',
    },
  },
  {
    name: 'Md.Shohanoor Rahman Shuvo',
    id: 21,
    shift: 'Morning',
    phoneNumber: '01770450977',
    university: {
      name: 'National Institute of Textile Engineering and Research (NITER)',
      department: 'Textile Engineering',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1997-09-17',
    address: {
      present: 'Noyarhat,Savar, Dhaka',
      permanent: 'Mashkanda,Mymensingh',
    },
    image: '/assets/avatars/md-shohanoor-rahman-shuvo__morning__01770450977.jpg',
    links: {
      facebook: 'https://www.facebook.com/shohanoorrahman.shuvo?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Sakib Ahamad',
    id: 22,
    shift: 'Morning',
    phoneNumber: '01316301931',
    university: {
      name: 'Mymensingh Medical College',
      department: 'MBBS',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-09-05',
    address: {
      present: 'Mymensingh',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/sakib-ahamad__morning__01316301931.jpg',
  },
  {
    name: 'Hasan Sajid',
    id: 23,
    shift: 'Morning',
    phoneNumber: '01710788172',
    university: {
      name: 'National Institute of Textile Engineering and Research (NITER)',
      department: 'IPE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-03-16',
    address: {
      present: 'Zia health, Bazrapur,Vocational, Jamalpur SADAR',
      permanent: 'Zia health, Bazrapur,Vocational, Jamalpur SADAR',
    },
    image: '/assets/avatars/hasan-sajid__morning__01710788172.jpg',
    links: {
      facebook: 'https://www.facebook.com/hasan.sajid.hasan',
    },
  },
  {
    name: 'Md.Shakib hossen',
    id: 24,
    shift: 'Morning',
    phoneNumber: '01761483153',
    university: {
      name: 'Daffodil international University (DIU)',
      department: 'CSE',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-22',
    address: {
      present: 'Ashuliya, Khagan',
      permanent: 'Boshpara,Jamalpur',
    },
    image: '/assets/avatars/md-shakib-hossen__morning__01761483153.jpg',
    links: {
      facebook: 'https://www.facebook.com/shakibhossain.shohan',
    },
  },
  {
    name: 'Abdullah Anim',
    id: 25,
    shift: 'Morning',
    phoneNumber: '01703231253',
    university: {
      name: 'Bangladesh University of Business and technology (BUBT)',
      department: 'CSE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-06-21',
    address: {
      present: 'Mirpur-2,Dhaka',
      permanent: 'Gopalpur,Jamalpur',
    },
    image: '/assets/avatars/abdullah-anim__morning__01703231253.jpg',
    links: {
      facebook: 'https://www.facebook.com/abdullah.anim.7?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md.Rezwanul Haq',
    id: 26,
    shift: 'Morning',
    phoneNumber: '01733186349',
    university: {
      name: 'Jahangirnagar University (JU)',
      department: 'Law & justice',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-12-23',
    address: {
      present: 'Sardarpara,Jamalpur',
      permanent: 'Sardarpara,Jamalpur',
    },
    image: '/assets/avatars/md-rezwanul-haq__morning__01733186349.jpg',
    links: {
      facebook: 'https://www.facebook.com/rezwan.dip',
    },
  },
  {
    name: 'Nirob Debnath',
    id: 27,
    shift: 'Morning',
    phoneNumber: '01600326116',
    university: {
      name: 'Daffodil international University (DIU)',
      department: 'CSE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-09-16',
    address: {
      present: 'Dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/nirob-debnath__morning__01600326116.jpg',
    links: {
      facebook: 'https://www.facebook.com/nirobdebnath01/',
    },
  },
  {
    name: 'Sourav Basak Shuvo',
    id: 28,
    shift: 'Morning',
    phoneNumber: '01712887192',
    university: {
      name: 'Khulna University of Engineering & Technology (KUET)',
      department: 'Biomedical Engineering',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-20',
    address: {
      present: 'KUET, Khulna',
      permanent: 'Basakpara, Jamalpur',
    },
    image: '/assets/avatars/sourav-basak-shuvo__morning__01712887192.jpg',
    links: {
      facebook: 'https://www.facebook.com/sourav.basak.37051',
    },
  },
  {
    name: 'Mirza Ashiqur Rahman (Tapos)',
    id: 29,
    shift: 'Morning',
    phoneNumber: '01521565602',
    university: {
      name: 'Bangladesh University of Engineering and Technology (BUET)',
      department: 'CE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-14',
    address: {
      present: 'Tejkunipara, Dhaka',
      permanent: 'Islampur, Jamalpur',
    },
    image: '/assets/avatars/mirza-ashiqur-rahman-tapos__morning__01521565602.jpg',
    links: {
      facebook: 'https://www.facebook.com/ashikur.tapos/',
    },
  },
  {
    name: 'Abdul Kahhar Siddiqui Sabbi',
    id: 30,
    shift: 'Morning',
    phoneNumber: '01956527080',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'IER',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-10-11',
    address: {
      present: 'Dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/abdul-kahhar-siddiqui-sabbi__morning__01956527080.jpg',
    links: {
      facebook: 'https://www.facebook.com/aksiddiqui.sabbi?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Mohammad Abdullahel Rafi',
    id: 31,
    shift: 'Morning',
    phoneNumber: '01790675975',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-12-08',
    address: {
      present: 'Dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/mohammad-abdullahel-rafi__morning__01790675975.jpg',
    links: {
      facebook: 'https://www.facebook.com/rafi.hasan.9210/',
    },
  },
  {
    name: 'Fardin Faiaz Khan',
    id: 32,
    shift: 'Morning',
    phoneNumber: '01741469635',
    university: {
      name: 'East West University (EWU)',
      department: 'BBA',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-07-11',
    address: {
      present: 'Mirpur 15, dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/fardin-faiaz-khan__morning__01741469635.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100008296176156&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Akash Mahatu',
    id: 33,
    shift: 'Morning',
    phoneNumber: '01679444685',
    university: {
      name: 'Dhaka College (DC)',
      department: 'Economics',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-11-17',
    address: {
      present: 'Dhaka College Hall',
      permanent: 'Bokultola, Jamalpur',
    },
    image: '/assets/avatars/akash-mahatu__morning__01679444685.jpg',
    links: {
      facebook: 'https://www.facebook.com/akash9907?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md Subbir Hossain Sunny',
    id: 34,
    shift: 'Morning',
    phoneNumber: '01886281849',
    university: {
      name: 'Govt. Ashek Mahmud College University',
      department: 'Chemistry',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-28',
    address: {
      present: 'Vill:Pathalia, Jamalpur Sadar, Jamalpur 2000',
      permanent: 'Vill:Pathalia, Jamalpur Sadar, Jamalpur 2000',
    },
    image: '/assets/avatars/md-subbir-hossain-sunny__morning__01886281849.jpg',
    links: {
      facebook: 'https://www.facebook.com/sunny.hossain.9400?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Mahin Habib Nibir',
    id: 35,
    shift: 'Morning',
    phoneNumber: '01751053072',
    university: {
      name: 'University of Chittagong (CU)',
      department: 'Public Administration ',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-06-01',
    address: {
      present: 'Shaeed Abdur Rob Hall, CU',
      permanent: 'Sardarpara, Jamalpur -2000',
    },
    image: '/assets/avatars/mahin-habib-nibir__morning__01751053072.jpg',
    links: {
      facebook: 'https://www.facebook.com/HerJunpyo?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md.Ariful Islam Siam',
    id: 36,
    shift: 'Morning',
    phoneNumber: '01710457485',
    university: {
      name: 'Chittagong Medical College (CMC)',
      department: 'MBBS',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-01',
    address: {
      present: 'Sector 9, uttara, Dhaka',
      permanent: 'Vill:Sanakoir, p/o:Baroi potol, upazilla: Sarishabari, zilla: Jamalpur',
    },
    image: '/assets/avatars/md-ariful-islam-siam__morning__01710457485.jpg',
    links: {
      facebook: 'https://www.facebook.com/arifulislam.siam.92?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Tafsir Ahmed Shovon',
    id: 37,
    shift: 'Morning',
    phoneNumber: '01792418111',
    university: {
      name: 'Dhaka College (DC)',
      department: 'Geography and environment ',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1998-02-07',
    address: {
      present: 'Akhteruzzaman eliyas hall, Dhaka College',
      permanent: 'West kacharipara,Jamalpur',
    },
    image: '/assets/avatars/tafsir-ahmed-shovon__morning__01792418111.jpg',
  },
  {
    name: 'MD.ABU BURHAN UDDIN SIJAR',
    id: 38,
    shift: 'Morning',
    phoneNumber: '01718200340',
    university: {
      name: 'Government Titumir College',
      department: 'Political Science and Law',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-15',
    address: {
      present: 'Jatrabari wapda colony',
      permanent: 'Islampur,Jamalpur',
    },
    image: '/assets/avatars/md-abu-burhan-uddin-sijar__morning__01718200340.jpg',
    links: {
      facebook: 'https://www.facebook.com/mdabuburhanuddin.sijar?mibextid=LQQJ4d',
    },
  },
  {
    name: 'Abid Sourav',
    id: 39,
    shift: 'Morning',
    phoneNumber: '01933228807',
    university: {
      name: 'Mawlana Bhashani Science and Technology University (MBSTU)',
      department: 'Biotechnology and Genetic Engineering',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-31',
    address: {
      present: 'Santosh,Tangail',
      permanent: 'Pathalia,Jamalpur',
    },
    image: '/assets/avatars/abid-sourav__morning__01933228807.jpg',
    links: {
      facebook: 'https://www.facebook.com/abidsourav69?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Moshiur Amin Shuvo',
    id: 40,
    shift: 'Morning',
    phoneNumber: '017110733778',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'IER',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-10-25',
    address: {
      present: 'Mohammadpur,Dhaka',
      permanent: 'Bokshigong, Jamalpur',
    },
    image: '/assets/avatars/moshiur-amin-shuvo__morning__017110733778.jpg',
    links: {
      facebook: 'https://www.facebook.com/moshiur.shuvo.5?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Latiful Khabir Shams',
    id: 41,
    shift: 'Morning',
    phoneNumber: '01986983453',
    university: {
      name: 'North South university (NSU)',
      department: 'BBA (marketing)',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-01-14',
    address: {
      present: 'Bashundhora, Dhaka',
      permanent: 'Kalabagan pach rasta , jamalpur',
    },
    image: '/assets/avatars/latiful-khabir-shams__morning__01986983453.jpg',
  },
  {
    name: 'Kamrul Hasan Likhon',
    id: 42,
    shift: 'Morning',
    phoneNumber: '01638755520',
    university: {
      name: 'Sheikh kamal Textile Engineering College, Jhenaidah',
      department: 'Textile Engineering',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-03-03',
    address: {
      present: 'Bot-tola, monirajpur, jamalpur',
      permanent: 'Bot-tola, monirajpur, jamalpur',
    },
    image: '/assets/avatars/kamrul-hasan-likhon__morning__01638755520.jpg',
    links: {
      facebook: 'https://www.facebook.com/kamrul.likhon?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Hasibul Hasan Rafi',
    id: 43,
    shift: 'Morning',
    phoneNumber: '01865184686',
    university: {
      name: 'United International University (UIU)',
      department: 'EEE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1998-04-11',
    address: {
      present: 'Banasree, Rampura, Dhaka',
      permanent: 'beltia jamalpur',
    },
    image: '/assets/avatars/hasibul-hasan-rafi__morning__01865184686.jpg',
    links: {
      facebook: 'https://www.facebook.com/hasanrafi101?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md.Istiak Awal Khan Jujule',
    id: 44,
    shift: 'Morning',
    phoneNumber: '01785731703',
    university: {
      name: 'Khulna University of Engineering & Technology (KUET)',
      department: 'EEE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-02-20',
    address: {
      present: 'gorarkanda,nandina,jamalpur',
      permanent: 'gorarkanda,nandina,jamalpur',
    },
    image: '/assets/avatars/md-istiak-awal-khan-jujule__morning__01785731703.jpg',
    links: {
      facebook: 'https://www.facebook.com/istiak.awalkhan',
    },
  },

  {
    name: 'MD Shajedul Islam (Sajal)',
    id: 45,
    shift: 'Morning',
    phoneNumber: '01824383484',
    university: {
      name: 'BGMEA University of Fashion & Technology (BUFT)',
      department: 'BBA',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-20',
    address: {
      present: 'Nikunja-2, Dhaka, Bangladesh',
      permanent: 'Char Horipur, Bhatara-2052, Sarishabari, Jamalpur.',
    },
    image: '/assets/avatars/md-shajedul-islam-sajal__morning__01824383484.jpg',
    links: {
      facebook: 'https://www.facebook.com/shajedul.sajal.7',
    },
  },
  {
    name: 'Nahid Hasan Tomal',
    id: 46,
    shift: 'Morning',
    phoneNumber: '01791203172',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Economics',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-03-10',
    address: {
      present: 'Hemayetpur, Savar, Dhaka',
      permanent: 'Dolvity, Sarishabari, Jamalpur',
    },
    image: '/assets/avatars/nahid-hasan-tomal__morning__01791203172.jpg',
    links: {
      facebook: 'https://www.facebook.com/nahidhasan.tomal.7',
    },
  },
  {
    name: 'Istiak Ahmed Shihab',
    id: 47,
    shift: 'Morning',
    phoneNumber: '01778918822',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Accounting & Information Systems',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-04-12',
    address: {
      present: '51-52 South Mugda, Dhaka-1214',
      permanent: 'Fulbaria Munshipara, Jamalpur',
    },
    image: '/assets/avatars/istiak-ahmed-shihab__morning__01778918822.jpg',
    links: {
      facebook: 'https://www.facebook.com/istiakahmed.shihab.5?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Nahid Hasan',
    id: 48,
    shift: 'Morning',
    phoneNumber: '01778668593',
    university: {
      name: 'Mymensingh Medical College',
      department: 'MBBS',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1998-10-25',
    address: {
      present: 'Mymensingh',
      permanent: 'Madergonj, Jamalpur',
    },
    image: '/assets/avatars/nahid-hasan__morning__01778668593.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100007690837774',
    },
  },
  {
    name: 'Al-Ruhul Amin Sabbir',
    id: 49,
    shift: 'Morning',
    phoneNumber: '01833065202',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-10-22',
    address: {
      present: 'Bashundhara',
      permanent: 'Iqbalpur,jamalpur',
    },
    image: '/assets/avatars/al-ruhul-amin-sabbir__morning__01833065202.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100077530563705',
    },
  },
  {
    name: 'Kibria',
    id: 50,
    shift: 'Morning',
    phoneNumber: '01748314303',
    university: {
      name: 'Rajshahi University of Engineering & Technology (RUET)',
      department: 'Civil Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-09-29',
    address: {
      present: 'RUET,Rajshahi',
      permanent: 'Bagabaid,Jamalpur',
    },
    image: '/assets/avatars/kibria__morning__01748314303.jpg',
    links: {
      facebook: 'https://www.facebook.com/gk.Kibria.303?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Mahraf Fahmid',
    id: 51,
    shift: 'Morning',
    phoneNumber: '01977009341',
    university: {
      name: 'Jagannath University (JnU)',
      department: 'Law',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-10-06',
    address: {
      present: 'Old Dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/mahraf-fahmid__morning__01977009341.jpg',
    links: {
      facebook: 'www.facebook.com/mahraf.fahmid',
    },
  },
  {
    name: 'Md.Jawadul Kabir Showdha',
    id: 52,
    shift: 'Morning',
    phoneNumber: '01639339476',
    university: {
      name: 'Jahangirnagar University (JU)',
      department: 'Urban and Regional Planning',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-01',
    address: {
      present: 'MH Hall,Jahangirnagar University',
      permanent: 'Holding No:1061,Pachrastar More,Miapara,Jamalpur',
    },
    image: '/assets/avatars/md-jawadul-kabir-showdha__morning__01639339476.jpg',
    links: {
      facebook: 'www.facebook.com/jawadul_kabir',
    },
  },
  {
    name: 'Aminul Shihab',
    id: 53,
    shift: 'Morning',
    phoneNumber: '01703419683',
    university: {
      name: 'National Institute of Textile Engineering and Research (NITER)',
      department: 'IPE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-08-26',
    address: {
      present: 'Savar',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/aminul-shihab__morning__01703419683.jpg',
    links: {
      facebook: 'https://www.facebook.com/aminulislam.shihab.77?mibextid=ZbWKwL',
    },
  },

  {
    name: 'Rubayed Sadnan Apurba',
    id: 54,
    shift: 'Morning',
    phoneNumber: '01628912090',
    university: {
      name: 'Sylhet Polytechnic Institute (SPI)',
      department: 'Civil',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-02-15',
    address: {
      present: 'DEUT 2nd Campus ,West Vurulia.Gazipur',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/rubayed-sadnan-apurba__morning__01628912090.jpg',
    links: {
      facebook: 'https://www.facebook.com/aminulislam.shihab.77?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md shariful islam',
    id: 55,
    shift: 'Morning',
    phoneNumber: '01950700188',
    university: {
      name: 'Textile Institute Tangail',
      department: 'Textile',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2001-10-15',
    address: {
      present: 'Valuka,gazipur, dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/md-shariful-islam__morning__01950700188.jpg',
    links: {
      facebook: 'https://www.facebook.com/inexcusable.shariful?mibextid=ZbWKwL',
    },
  },

  {
    name: 'Iftear Hossen Pranto',
    id: 56,
    shift: 'Morning',
    phoneNumber: '01409114790',
    university: {
      name: 'Sherpur polytechnic institute',
      department: 'Diploma in electrical engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-11-13',
    address: {
      present: 'Gazipur, Joydebpur',
      permanent: 'Miapara, Jamalpur sadar',
    },
    image: '/assets/avatars/iftear-hossen-pranto__morning__01409114790.jpg',
    links: {
      facebook: 'https://www.facebook.com/pranto.pranto32?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Mohammad Nashrullah Naum',
    id: 57,
    shift: 'Morning',
    phoneNumber: '01798569744',
    university: {
      name: 'Bangladesh University of Professionals (BUP)',
      department: 'Information and Communication Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-11-30',
    address: {
      present: 'Mirpur-12',
      permanent: 'Islampur',
    },
    image: '/assets/avatars/mohammad-nashrullah-naum__morning__01798569744.jpg',
    links: {
      facebook: 'https://www.facebook.com/nashrullah.naum?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Piash Mahmud',
    id: 58,
    shift: 'Morning',
    phoneNumber: '01643963202',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Software Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-31',
    address: {
      present: 'Banglamotor, Dhaka',
      permanent: 'Melandha, Jamalpur',
    },
    image: '/assets/avatars/piash-mahmud__morning__01643963202.jpg',
    links: {
      facebook: 'https://www.facebook.com/MahmudPiash.2',
    },
  },

  {
    name: 'Tufael Alam',
    id: 59,
    shift: 'Morning',
    phoneNumber: '01626065056',
    university: {
      name: 'Rajshahi University of Engineering & Technology (RUET)',
      department: 'EEE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-02-07',
    address: {
      present: 'Shahid Abdul Hamid Hall ,RUET ,Rajshahi',
      permanent: 'Kamaler Bartti-2140 , Bakshigonj ,Jamalpur',
    },
    image: '/assets/avatars/tufael-alam__morning__01626065056.jpg',
    links: {
      facebook: 'https://www.facebook.com/ratan.ahmed.986',
    },
  },

  {
    name: 'Jubayer Al Mahmud Joy',
    id: 60,
    shift: 'Morning',
    phoneNumber: '01932848912',
    university: {
      name: 'Leading University',
      department: 'Civil Engineering',
    },
    bloodGroup: 'B_positive',
    occupation: 'Survey Engineer',
    dateOfBirth: '2000-11-19',
    address: {
      present: 'Airport road,patenga,Chattogram ',
      permanent: 'Goforgaon,Mymensingh ',
    },
    image: '/assets/avatars/jubayer-al-mahmud-joy__morning__01932848912.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100008062299313&mibextid=ZbWKwL',
    },
  },
  {
    name: 'MD. Rakibul Islam',
    id: 61,
    shift: 'Morning',
    phoneNumber: '01521514709',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Applied Chemistry and Chemical Engineering ',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2001-06-01',
    address: {
      present: 'Dr. Muhammad Shahidullah Hall,DU',
      permanent: 'Boshpara,Jamalpur',
    },
    image: '/assets/avatars/md-rakibul-islam__morning__01521514709.jpg',
    links: {
      facebook: 'https://www.facebook.com/rakiib.234?mibextid=ZbWKwL',
    },
  },
  {
    name: 'MD Abdul Aziz',
    id: 62,
    shift: 'Morning',
    phoneNumber: '01718968410',
    university: {
      name: 'Govt. Ashek Mahmud College University',
      department: 'BBS(Bachelor  of business studies)',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-06-01',
    address: {
      present: 'Station para,Jamalpur',
      permanent: 'Station para,Jamalpur',
    },
    image: '/assets/avatars/md-abdul-aziz__morning__01718968410.jpg',
    links: {
      facebook: 'https://www.facebook.com/rakiib.234?mibextid=ZbWKwL',
    },
  },
  // {
  //   name: 'Mustakim Ahmod Shawn',
  //   id: 63,
  //   shift: 'Morning',
  //   phoneNumber: '01625687944',
  //   university: {
  //     name: 'National Institute of Textile Engineering and Research (NITER)',
  //     department: 'Apparel engineering',
  //   },
  //   bloodGroup: 'O_positive',
  //   occupation: 'Student',
  //   dateOfBirth: '1999-09-08',
  //   address: {
  //     present: 'Savar',
  //     permanent: 'Mahmudpur,melandha,Jamalpur',
  //   },
  //   image: '/assets/avatars/mustakim-ahmod-shawn__morning__01625687944.jpg',
  //   links: {
  //     facebook: 'https://www.facebook.com/shawn.shawn.7965692',
  //   },
  // },
  {
    name: 'Mustakim Ahmod Shawn',
    id: 64,
    shift: 'Morning',
    phoneNumber: '01625687944',
    university: {
      name: 'National Institute of Textile Engineering and Research (NITER)',
      department: 'Apparel engineering',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-09-08',
    address: {
      present: 'Savar',
      permanent: 'Mahmudpur,melandha,Jamalpur',
    },
    image: '/assets/avatars/mustakim-ahmod-shawn__morning__01625687944.jpg',
    links: {
      facebook: 'https://www.facebook.com/shawn.shawn.7965692',
    },
  },
  {
    name: 'Mubtasim Alam Musavee',
    id: 65,
    shift: 'Day',
    phoneNumber: '01790776959',
    university: {
      name: 'Daffodil international University (DIU)',
      department: 'Law',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-17',
    address: {
      present: '645/6, Bashundhara lane, West Kazipara, Mirpur, Dhaka 1216',
      permanent: '448/2, Hoque Monjil, Noyapara, Jamalpur 2000',
    },
    image: '/assets/avatars/mubtasim-alam-musavee__day__01790776959.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100008186118794',
    },
  },

  {
    name: 'Salman Sarker',
    id: 66,
    shift: 'Day',
    phoneNumber: '01754945099',
    university: {
      name: 'Jatiya Kabi Kazi Nazrul Islam University (JKKNIU)',
      department: 'Management ',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1997-12-26',
    address: {
      present: 'Golapbug,Jamalpur',
      permanent: 'Golapbug,Jamalpur',
    },
    image: '/assets/avatars/salman-sarker__day__01754945099.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100010076717209&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Tanvir Ahmed Utsha',
    id: 67,
    shift: 'Day',
    phoneNumber: '01624775507',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE ',
    },
    bloodGroup: 'B_positive',
    occupation: 'Software Quality Assurance Engineer',
    dateOfBirth: '2000-11-12',
    address: {
      present: ' Nikunja-2,Dhaka-1229',
      permanent: 'Boshpara,Jamalpur Sadar,Jamalpur-2000',
    },
    image: '/assets/avatars/tanvir-ahmed-utsha__day__01624775507.jpg',
    links: {
      facebook: 'https://www.facebook.com/tanvir7755',
    },
  },
  {
    name: 'Alimul Raji BONDHON',
    id: 68,
    shift: 'Day',
    phoneNumber: '01927768192',
    university: {
      name: 'Nandina shekh anowar hossain college',
      department: 'Accounting',
    },
    bloodGroup: 'B_positive',
    occupation: 'Assistant General Manager at Best life insurance limited',
    dateOfBirth: '2000-11-14',
    address: {
      present: 'Kalighat,Jamalpur',
      permanent: 'Kalighat,Jamalpur',
    },
    image: '/assets/avatars/alimul-raji-bondhon__day__01927768192.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100077023862630&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md. Bakhtier Galib',
    id: 69,
    shift: 'Day',
    phoneNumber: '01869461003',
    university: {
      name: 'Shahjalal University of Science and Technology (SUST)',
      department: 'Department of Architecture ',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-11-27',
    address: {
      present: 'Akhalia, Sylhet',
      permanent: 'Dakkhin Kacharipara, New college Road, Jamalpur',
    },
    image: '/assets/avatars/md-bakhtier-galib__day__01869461003.jpg',
    links: {
      facebook: 'https://www.facebook.com/md.bakhtier.galib',
    },
  },
  {
    name: 'Md. Hashibur Rahman',
    id: 70,
    shift: 'Day',
    phoneNumber: '01724748400',
    university: {
      name: 'Sylhet MAG Osmani Medical College',
      department: 'Mbbs',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-10',
    address: {
      present: 'Kendua Kalibari,Jamalpur',
      permanent: 'Kendua Kalibari,Jamalpur',
    },
    image: '/assets/avatars/md-hashibur-rahman__day__01724748400.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100090151094956&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Abu Sayad Rahman Siam',
    id: 72,
    shift: 'Day',
    phoneNumber: '01956792098',
    university: {
      name: 'Dr. M A Wazed Miah Textile Engineering College',
      department: 'Apparel Engineering',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-18',
    address: {
      present: 'Rangpur',
      permanent: 'Islampur,Jamalpur',
    },
    image: '/assets/avatars/abu-sayad-rahman-siam__day__01956792098.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100089469551903&mibextid=ZbWKwL',
    },
  },
  {
    name: 'MD. Shakhawat Hossain Shawon',
    id: 73,
    shift: 'Day',
    phoneNumber: '01772284345',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Biochemistry and molecular biology',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-26',
    address: {
      present: 'Lalbagh, Dhaka',
      permanent: 'Monirajpur, Jamalpur Sadar,Jamalpur',
    },
    image: '/assets/avatars/md-shakhawat-hossain-shawon__day__01772284345.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100012632020362&mibextid=LQQJ4d',
    },
  },
  {
    name: 'Suad As Samih',
    id: 74,
    shift: 'Day',
    phoneNumber: '01969146338',
    university: {
      name: 'University Putra Malaysia',
      department: 'BBA(Entrepreneurship)',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2001-11-06',
    address: {
      present: 'Serdang Pardana,43400,Seri Kambangan,Malaysia',
      permanent: 'Balijhuri Bazar,Madarganj,Jamalpur.',
    },
    image: '/assets/avatars/suad-as-samih__day__01969146338.jpg',
    links: {
      facebook: 'https://www.facebook.com/suad523?mibextid=LQQJ4d',
    },
  },
  {
    name: 'Shofiul Alam Shimanto',
    id: 75,
    shift: 'Day',
    phoneNumber: '01788676008',
    university: {
      name: 'Ahsanullah University of Science and Technology (AUST)',
      department: 'IPE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-16',
    address: {
      present: 'Modhuvag,Mogbazar-Dhaka',
      permanent: 'Pathaliya,Jamalpur',
    },
    image: '/assets/avatars/shofiul-alam-shimanto__day__01788676008.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100009492227625',
    },
  },
  {
    name: 'Shaimum islam Reshad',
    id: 76,
    shift: 'Day',
    phoneNumber: '01690130606',
    university: {
      name: 'University of Chittagong (CU)',
      department: 'Persian Language and literature',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1998-03-11',
    address: {
      present: 'Jamalpur',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/shaimum-islam-reshad__day__01690130606.jpg',
  },

  {
    name: 'Hasibul Hasan Siam',
    id: 77,
    shift: 'Day',
    phoneNumber: '01760071104',
    university: {
      name: 'Hajee Mohammad Danesh Science & Technology University (HSTU)',
      department: 'Architecture',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-04-30',
    address: {
      present: 'Dinajpur, Rangpur, Bangladesh',
      permanent: 'Mukundabari, Jamalpur',
    },
    image: '/assets/avatars/hasibul-hasan-siam__day__01760071104.jpg',
    links: {
      facebook: 'https://www.facebook.com/hasibul.hasan.54738',
    },
  },
  {
    name: 'Shahriar Ahmmed',
    id: 78,
    shift: 'Day',
    phoneNumber: '01706032795',
    university: {
      name: 'Bangladesh University of Business and technology (BUBT)',
      department: 'CSE',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-01-28',
    address: {
      present: 'Rupnagar Residential, Mirpur 2,Dhaka',
      permanent: 'Dewanpara,Lichutala,Jamalpur',
    },
    image: '/assets/avatars/shahriar-hmmed__day__01706032795.jpg',
    links: {
      linkedin: 'https://www.linkedin.com/in/shahriar-ahmmed-25102b270',
    },
  },
  {
    name: 'Md. Rakibul Islam Bilash',
    id: 79,
    shift: 'Day',
    phoneNumber: '01974675572',
    university: {
      name: 'Rajshahi University of Engineering & Technology (RUET)',
      department: 'Glass & Ceramic Engineering(GCE)',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-02-01',
    address: {
      present: 'jigatola,jamalpur',
      permanent: 'jigatola,jamalpur',
    },
    image: '/assets/avatars/md-rakibul-islam-bilash__day__01784675571.jpg',
    links: {
      facebook: 'https://www.facebook.com/rakibulislam.bilash.9',
    },
  },
  {
    name: 'K.M Shihab Fardin',
    id: 80,
    shift: 'Day',
    phoneNumber: '01986717683',
    university: {
      name: 'University of Liberal Arts Bangladesh (ULAB)',
      department: 'BBA',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '1999-02-08',
    address: {
      present: 'Green Road, Dhaka',
      permanent: 'Ranigonj Bazar, Jamalpur sadar',
    },
    image: '/assets/avatars/k-m-shihab-fardin __day__01986717683.jpg',
    links: {
      facebook: 'https://www.facebook.com/shihab.fardin.5',
    },
  },
  {
    name: 'Joy Debnath',
    id: 81,
    shift: 'Day',
    phoneNumber: '01761238131',
    university: {
      name: 'Chittagong Medical College (CMC)',
      department: 'MBBS',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-09-27',
    address: {
      present: 'Medical Main Boys Hostel,Chatteswari road,Chittagong',
      permanent: 'Tomaltala, Jamalpur Sadar,Jamalpur',
    },
    image: '/assets/avatars/joy-debnath__day__01761238131.jpg',
    links: {
      facebook: 'https://www.facebook.com/joy.debnath.737448?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Shaien Kadir Apurba',
    id: 82,
    shift: 'Day',
    phoneNumber: '01649118574',
    university: {
      name: 'Rajshahi University of Engineering & Technology (RUET)',
      department: 'IPE',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '1999-09-07',
    address: {
      present: 'Uttar kacharipara, Jamalpur ',
      permanent: 'Uttar kacharipara, Jamalpur',
    },
    image: '/assets/avatars/shaien-kadir-apurba__day__01649118574.jpg',
  },
  {
    name: 'Sakibul Haque',
    id: 83,
    shift: 'Day',
    phoneNumber: '01761238131',
    university: {
      name: 'Govt. Ashek Mahmud College University',
      department: 'Philosophy',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-12-11',
    address: {
      present: 'Saradarpara, Panch Rastar Mor, Jamalpur Sadar, Jamalpur',
      permanent: 'Saradarpara, Panch Rastar Mor, Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/sakibul-haque__day__01925877890.jpg',
    links: {
      facebook: 'https://www.facebook.com/hq.safer',
    },
  },

  {
    name: 'Asraful islam sifat',
    id: 84,
    shift: 'Day',
    phoneNumber: '01753177697',
    university: {
      name: 'National University',
      department: 'Accounting',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2000-08-24',
    address: {
      present: 'Mukundobari, jamalpur sadar, jamalpur',
      permanent: 'Mukundobari, jamalpur sadar, jamalpur',
    },
    image: '/assets/avatars/asraful-islam-sifat__day__01753177697.jpg',
    links: {
      facebook: 'https://www.facebook.com/asraful.sifat',
    },
  },
  {
    name: 'Nur Bin Ferdous Pavel',
    id: 85,
    shift: 'Day',
    phoneNumber: '01761349779',
    university: {
      name: 'Bangamata Sheikh Fojilatunnesa Mujib Science & Technology University',
      department: 'Management',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2001-08-26',
    address: {
      present: 'Hat Chandra Jamalpur',
      permanent: 'Hat Chandra Jamalpur',
    },
    image: '/assets/avatars/nur-bin-ferdous-pavel__day__01761349779.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100004360399270',
    },
  },
  {
    name: 'Shakibuzzaman Saad',
    id: 86,
    shift: 'Day',
    phoneNumber: "'01775243737",
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-02-02',
    address: {
      present: 'Bashundhara R/A ,Dhaka',
      permanent: 'Digpait, Jamalpur',
    },
    image: '/assets/avatars/shakibuzzaman-saad__day__01775243737.jpg',
    links: {
      facebook: 'https://www.facebook.com/shakibuzzaman.saad.302?mibextid=LQQJ4d',
    },
  },
  {
    name: 'Injamamul Hoque Labib',
    id: 87,
    shift: 'Day',
    phoneNumber: '01795938261',
    university: {
      name: 'Shahjalal University of Science and Technology (SUST)',
      department: 'oceanography ',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-10',
    address: {
      present: 'Akhaliya,Sylhet',
      permanent: 'Pathaliya,Jamalpur',
    },
    image: '/assets/avatars/injamamul-hoque-labib__day__01795938261.jpg',
    links: {
      facebook: 'https://www.facebook.com/injamam.labib?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Atik Faisal',
    id: 88,
    shift: 'Day',
    phoneNumber: '01581111461',
    university: {
      name: 'State University Of Bangladesh',
      department: 'Food Fundamentals',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1998-07-10',
    address: {
      present: 'Kishoreganj ',
      permanent: 'Kishoreganj',
    },
    image: '/assets/avatars/atik-faisal__day__01581111461.jpg',
    links: {
      facebook: 'https://www.facebook.com/atik.faisal.395?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Ayat Ullah',
    id: 89,
    shift: 'Day',
    phoneNumber: '01518369111',
    university: {
      name: 'Govt. Unani & Ayurvedic Medical College',
      department: 'Ayurvedic Medicine & Surgery',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-12-30',
    address: {
      present: 'Mirpur-13',
      permanent: 'Bagerhata, Ziya College Road, Jamalpur-2000',
    },
    image: '/assets/avatars/ayat-ullah__day__01518369111.jpg',
    links: {
      facebook: 'https://www.facebook.com/Dr.AyatullahBAMS',
    },
  },
  {
    name: 'Sazzad Hossain',
    id: 90,
    shift: 'Day',
    phoneNumber: '01840734867',
    university: {
      name: 'Chittagong University of Engineering & Technology (CUET)',
      department: 'Electrical & Electronic Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-11-28',
    address: {
      present: 'Jamalpur, Bangladesh',
      permanent: 'Jamalpur, Bangladesh',
    },
    image: '/assets/avatars/sazzad-hossain__day__01840734867.jpg',
    links: {
      facebook: 'https://www.facebook.com/sazzad80215?mibextid=ZbWKwL',
    },
  },

  {
    name: 'Sami',
    id: 91,
    shift: 'Day',
    phoneNumber: '01795838491',
    university: {
      name: 'Govt. Ashek Mahmud College University',
      department: 'Mathematics',
    },
    bloodGroup: 'O_positive',
    occupation: 'Business',
    dateOfBirth: '2000-01-05',
    address: {
      present: 'Doripara Jamalpur ',
      permanent: 'Doripara Jamalpur ',
    },
    image: '/assets/avatars/sami__day__01795838491.jpg',
    links: {
      facebook: 'https://www.facebook.com/samiul.bashar.92',
    },
  },
  {
    name: 'Mustak Nadim',
    id: 92,
    shift: 'Day',
    phoneNumber: '01704669823',
    university: {
      name: 'Rangpur medical college',
      department: 'Mbbs',
    },
    bloodGroup: 'O_positive',
    occupation: 'student',
    dateOfBirth: '1998-06-17',
    address: {
      present: 'Pachrastar moar',
      permanent: 'Pachrastar moar',
    },
    image: '/assets/avatars/mustak-nadim__day__01704669823.jpg',
    // links: {
    //   facebook: "https://www.facebook.com/samiul.bashar.92",
    // }
  },
  {
    name: 'MD. KHALEQUZZAMAN',
    id: 93,
    shift: 'Day',
    phoneNumber: '01704669823',
    university: {
      name: 'Sheikh Fazilatunnesa Mujib University, Jamalpur',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-09',
    address: {
      present: 'Langoljura, Jamalpur Sadar, Jamalpur',
      permanent: 'Langoljura, Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/md-khalequzzaman__day__01316006374.jpg',
    links: {
      facebook: 'https://www.facebook.com/mdkhalequzzamanmahin',
    },
  },
  {
    name: 'Naeem Hasan',
    id: 94,
    shift: 'Day',
    phoneNumber: '01704669814',
    university: {
      name: 'University of Chittagong (CU)',
      department: 'English',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '1999-05-06',
    address: {
      present: 'Chattogram',
      permanent: 'Monirazpur,Jamalpur',
    },
    image: '/assets/avatars/naeem-hasan__day__01704669814.jpg',
    // links: {
    //   facebook: "https://www.facebook.com/mdkhalequzzamanmahin",
    // }
  },
  {
    name: 'Md. Mehedi Hasan Khan',
    id: 95,
    shift: 'Day',
    phoneNumber: '01517853281',
    university: {
      name: 'Tejgaon College',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-20',
    address: {
      present: 'House-2, Road-3, Ali and Noor Real State, Mohammadpur, Dhaka-1207',
      permanent: 'Uttar Kacharipara, Jamalpur Sadar Jamalpur -2000',
    },
    image: '/assets/avatars/md-mehedi-hasan-khan__day__01517853281.jpg',
    links: {
      facebook: 'https://facebook.com/mehedi.care',
    },
  },
  {
    name: 'Kausar',
    id: 96,
    shift: 'Day',
    phoneNumber: '01885860791',
    university: {
      name: 'National University',
      department: 'English',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-05',
    address: {
      present: 'Sirajganj',
      permanent: 'Jamalpur Sadar Jamalpur -2000',
    },
    image: '/assets/avatars/kausar__day__01885860791.jpg',
    links: {
      facebook: 'https://www.facebook.com/iliash.kausar.56',
    },
  },
  {
    name: 'Abdur Rohim',
    id: 97,
    shift: 'Day',
    phoneNumber: '01785299180',
    university: {
      name: 'Ananda Mohon College Mymensingh',
      department: 'Mathematics',
    },
    bloodGroup: 'O_positive',
    occupation: 'Teacher',
    dateOfBirth: '2000-08-25',
    address: {
      present: 'Bank colony, new College road,Jamalpur',
      permanent: 'Bank colony, new college road,Jamalpur ',
    },
    image: '/assets/avatars/abdur-rohim__day__01785299180.jpg',
    links: {
      facebook: 'https://www.facebook.com/srarobin.hasan?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Tanzir-Ul Prodhan Srizon',
    id: 98,
    shift: 'Day',
    phoneNumber: '01756238761',
    university: {
      name: 'Rajshahi University of Engineering & Technology (RUET)',
      department: 'Civil Engineering',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '1999-11-29',
    address: {
      present: 'Rajshahi',
      permanent: 'Nayapara,Jamalpur Sadar',
    },
    image: '/assets/avatars/tanzir-ul-prodhan-srizon__day__1756238761.jpg',
    links: {
      facebook: 'https://www.facebook.com/tanjirulprodhan.srizon?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Udvas Ghose Sebak',
    id: 99,
    shift: 'Day',
    phoneNumber: '01993274058',
    university: {
      name: 'Ananda Mohon College Mymensingh',
      department: 'History',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-10',
    address: {
      present: 'Amlapara Jamalpur',
      permanent: 'Amlapara Jamalpur',
    },
    image: '/assets/avatars/udvas-ghose-sebak__day__01993274058.jpg',
    // links: {
    //   facebook: "https://www.facebook.com/tanjirulprodhan.srizon?mibextid=ZbWKwL",
    // }
  },
  {
    name: 'Israk afjal noor',
    id: 100,
    shift: 'Day',
    phoneNumber: '01793355362',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Software engineer',
    dateOfBirth: '1998-10-12',
    address: {
      present: 'Dhaka ',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/israk-afjal-noor__day__01793355362.jpg',
    links: {
      facebook: 'https://www.facebook.com/israk.antu.9?mibextid=LQQJ4d',
    },
  },
  {
    name: 'Raisul Islam Rana',
    id: 101,
    shift: 'Morning',
    phoneNumber: '01773467229',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'History',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '1999-12-11',
    address: {
      present: 'Shahid Sergeant Zahurul Haque Hall,DU',
      permanent: 'Gatepar, Jamalpur',
    },
    image: '/assets/avatars/raisul-islam-rana__morning__01773467229.jpg',
    links: {
      facebook: 'https://www.facebook.com/raisulislam.rana.12',
    },
  },
  {
    name: 'Shihab Shaharia Shishir',
    id: 102,
    shift: 'Day',
    phoneNumber: '01736990444',
    university: {
      name: 'Govt. Shaheed Suhrawardy College, Dhaka',
      department: 'Botany',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-31',
    address: {
      present: 'Dhaka',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/shihab-shaharia-shishir__day__01736990444.jpg',
    links: {
      facebook: 'https://www.facebook.com/shihab.shaharia.946?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Nozmus Salehin',
    id: 103,
    shift: 'Day',
    phoneNumber: '01791273038',
    university: {
      name: 'Ahsanullah University of Science and Technology (AUST)',
      department: 'IPE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-12-19',
    address: {
      present: 'Hazi Vhaban (opposite of shwapno) , Modhugbag, Moghbazar, Dhaka',
      permanent: 'Vill: Ramnagar, Jamalpur',
    },
    image: '/assets/avatars/nozmus-salehin__day__01791273038.jpg',
    links: {
      facebook: 'https://www.facebook.com/md.salehin.313?mibextid=ZbWKwL',
    },
  },
  {
    name: 'K.A.S.M TAHMID PROTIK',
    id: 104,
    shift: 'Day',
    phoneNumber: '01521514731',
    university: {
      name: 'University of Dhaka (DU)',
      department: 'Faculty Of Medicine',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-09-11',
    address: {
      present: 'Dhaka',
      permanent: 'Sherpur',
    },
    image: '/assets/avatars/k-a-s-m-tahmid-protik__day__01521514731.jpg',
    links: {
      facebook: 'https://www.facebook.com/protik.khan.925?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md. Sakibur Rahman',
    id: 105,
    shift: 'Day',
    phoneNumber: '01558997604',
    university: {
      name: 'Government Titumir College',
      department: 'Accounting',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-10-23',
    address: {
      present: 'Kaderabad housing, Mohammadpur, dhaka',
      permanent: 'Uttar kacharipara, jamalpur sadar, jamalpur',
    },
    image: '/assets/avatars/md-sakibur-rahman__day__01558997604.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100013913474167&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Mubasher Adnan Jihad',
    id: 106,
    shift: 'Day',
    phoneNumber: '01795582173',
    university: {
      name: 'Jahangirnagar University (JU)',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-11-27',
    address: {
      present: 'Dhaka',
      permanent: 'Jamalpur sadar, jamalpur',
    },
    image: '/assets/avatars/mubasher-adnan-jihad__day__01795582173.jpg',
    links: {
      facebook: 'https://web.facebook.com/profile.php?id=100006963810087',
    },
  },
  {
    name: 'Tanvir Mahtab',
    id: 107,
    shift: 'Day',
    phoneNumber: '01798569640',
    university: {
      name: 'Khulna University (KU)',
      department: 'Architecture',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-06-21',
    address: {
      present: 'Khan bahadur Ahsanullah hall, Khulna University, Khulna',
      permanent: 'village: Adra; P.O: Adra Bazar; Melandaha, Jamalpur',
    },
    image: '/assets/avatars/tanvir-mahtab__day__01798569640.jpg',
    links: {
      facebook: 'https://www.facebook.com/tanvirmahtab.riham',
    },
  },
  {
    name: 'Zakaria Hossain Rifat',
    id: 108,
    shift: 'Day',
    phoneNumber: '01952811405',
    university: {
      name: 'Primeasia University',
      department: 'Textile',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-12-23',
    address: {
      present: 'Khilkhet,Dhaka',
      permanent: 'Shahapur,Jamalpur',
    },
    image: '/assets/avatars/zakaria-hossain-rifat__day__01952811405.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100008172603153&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Shoikot khan',
    id: 109,
    shift: 'Day',
    phoneNumber: '01627174843',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'EEE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-25',
    address: {
      present: 'Basundhara Residential Area',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/shoibal-khan__day__018837032410.jpg',
    links: {
      facebook: 'https://www.facebook.com/shoi.kot.9?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md Akash Islam Razu',
    id: 110,
    shift: 'Day',
    phoneNumber: '01521514715',
    university: {
      name: 'Ahsanullah University of Science and Technology (AUST)',
      department: 'IPE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-01',
    address: {
      present: 'Noyatola Moghbazar Dhaka',
      permanent: 'Bania Bazar Jamalpur',
    },
    image: '/assets/avatars/md-akash-islam-razu__day__01521514715.jpg',
    links: {
      facebook: 'https://www.facebook.com/akash.rz19',
    },
  },
  {
    name: 'Sazid Rahman Enan',
    id: 111,
    shift: 'Day',
    phoneNumber: '01716225620',
    university: {
      name: 'Jahangirnagar University (JU)',
      department: 'BBA',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-12-08',
    address: {
      present: 'Aambagan Bazar Road, Jahangirnagar University, Savar, Dhaka-1342, Bangladesh',
      permanent: 'Banakura Mor, Uttar Kachari Para, Shahid Harun Sarak, Jamalpur-2000, Bangladesh',
    },
    image: '/assets/avatars/sazid-rahman-enan__day__01716225620.jpg',
    links: {
      facebook: 'https://www.facebook.com/sazidrahman.enan/',
    },
  },
  {
    name: 'Badhan, Walid Bin Wahid',
    id: 112,
    shift: 'Day',
    phoneNumber: '01798429298',
    university: {
      name: 'American International University Bangladesh (AIUB)',
      department: 'CSE',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-13',
    address: {
      present: 'Bashundhara R/A',
      permanent: 'East Naya para, Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/walid-bin-wahid-badhan__day__01798429298.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100007300359329&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Shoibal khan',
    id: 113,
    shift: 'Day',
    phoneNumber: '01883703241',
    university: {
      name: 'Daffodil Technical Institute (DTI)',
      department: 'CSE',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2000-05-25',
    address: {
      present: 'Dhanmondi-32',
      permanent: 'Kacharipara,Jamalpur',
    },
    image: '/assets/avatars/shoikot-khan__day__01627174843.jpg',
    links: {
      facebook: 'https://www.facebook.com/shoibal.khan.969?mibextid=LQQJ4d',
    },
  },
  {
    name: 'Sattyajit Das',
    id: 114,
    shift: 'Day',
    phoneNumber: '01704052948',
    university: {
      name: 'Shahjalal University of Science and Technology (SUST)',
      department: 'Dept. of Architecture',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '1999-09-10',
    address: {
      present: '19, Topobon R/A, Akhalia',
      permanent: 'Dokkhin Kisammat jalla, Islampur, Jamalpur',
    },
    image: '/assets/avatars/sattyajit-das__day__01704052948.jpg',
    links: {
      facebook: 'https://www.facebook.com/sattyajit.shuddha',
    },
  },
  {
    name: 'Rafi Talukder',
    id: 115,
    shift: 'Day',
    phoneNumber: '01781460693',
    university: {
      name: 'University of Rajshahi',
      department: 'Folklore',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '1999-02-28',
    address: {
      present: 'Rajshahi ',
      permanent: 'Nayapara,  Jamalpur',
    },
    image: '/assets/avatars/rafi-talukder__day__01781460693.jpg',
    links: {
      facebook: 'https://www.facebook.com/rafi.talukder.370?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md.Abu Bakkar Siddique Shuvo',
    id: 116,
    shift: 'Day',
    phoneNumber: '01304598596',
    university: {
      name: 'Syed Nazrul Islam Medical College,Kishoreganj',
      department: 'Mbbs',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-02-24',
    address: {
      present: 'Rayer Bakai,Vabki,Melandaha,Jamalpur',
      permanent: 'Rayer Bakai,Vabki,Melandaha,Jamalpur',
    },
    image: '/assets/avatars/md-abu-bakkar-siddique-shuvo__day__01304598596.jpg',
    links: {
      facebook: 'https://www.facebook.com/onirban.shuvo.7',
    },
  },
  {
    name: 'Saad Ibn Abdul Hakim',
    id: 117,
    shift: 'Day',
    phoneNumber: '01933227771',
    university: {
      name: 'Bangamata Sheikh Fojilatunnesa Mujib Science & Technology University',
      department: 'Management',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-28',
    address: {
      present: 'Miapara, Jamalpur',
      permanent: 'Binonderpara, Beltia, Jamalpur.',
    },
    image: '/assets/avatars/saad-ibn-abdul-hakim__day__01933227771.jpg',
    links: {
      facebook: 'https://www.facebook.com/saad.ibnabdulhakim',
    },
  },
  {
    name: 'Madhab Singh Rudra',
    id: 118,
    shift: 'Day',
    phoneNumber: '01644382647',
    university: {
      name: 'Khulna University of Engineering & Technology (KUET)',
      department: 'Biomedical Engineering',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-14',
    address: {
      present: 'Doyamoyee Road , Jamalpur ',
      permanent: 'Doyamoyee Road , Jamalpur',
    },
    image: '/assets/avatars/madhab-singh-rudra__day__01644382647.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100025825026293&mibextid=LQQJ4d',
    },
  },
  {
    name: 'Mahabub Rahaman Mim',
    id: 119,
    shift: 'Day',
    phoneNumber: '01763800319',
    // university: {
    //   name: "Khulna University of Engineering & Technology (KUET)",
    //   department: "Biomedical Engineering"
    // },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2000-08-29',
    address: {
      present: 'Nayapara, Jamalpur',
      permanent: 'Nayapara, Jamalpur',
    },
    image: '/assets/avatars/mahabub-rahaman-mim__day__01763800319.jpg',
    links: {
      facebook: 'https://facebook.com/profile.php?eav=AfbXoGrFIv8qeqzzJzTPwsAaNlHx6lXEQtpzSmjk955KjQqUcthnjjqD-dkOrkV2wGs&paipv=0&_rdr',
    },
  },
  {
    name: 'Arman Hossain',
    id: 120,
    shift: 'Day',
    phoneNumber: '01932726155',
    university: {
      name: 'Dhaka Polytechnic Institute',
      department: 'Electrical',
    },
    bloodGroup: 'A_positive',
    occupation: 'Sub Assistant Engineer',
    dateOfBirth: '2001-07-23',
    address: {
      present: 'Lichutala,Jamalpur',
      permanent: 'Lichutala,Jamalpur',
    },
    image: '/assets/avatars/arman-hossain__day__01932726155.jpg',
    links: {
      facebook: 'https://www.facebook.com/arman.anan.52?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Kausar Ahmed Piash',
    id: 121,
    shift: 'Day',
    phoneNumber: '01300890210',
    university: {
      name: 'Govt. Ashek Mahmud College University',
      department: 'Zology',
    },
    bloodGroup: 'A_positive',
    occupation: 'Digital Marketer',
    dateOfBirth: '1998-11-06',
    address: {
      present: 'Amlapara, Jamalpur',
      permanent: 'Town Hall , Mymensing',
    },
    image: '/assets/avatars/kausar-ahmed-piash__day__01300890210.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100077716329965&mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md Al Amin',
    id: 122,
    shift: 'Day',
    phoneNumber: '01742782625',
    university: {
      name: 'Bangladesh University of Engineering and Technology (BUET)',
      department: 'Mechanical Engineering',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2001-12-14',
    address: {
      present: 'Kazi N azrul Islam Hall , BUET',
      permanent: 'Kendua Kalibari , Jamalpur',
    },
    image: '/assets/avatars/md-al-amin__day__01742782625.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100011676334752&mibextid=LQQJ4d',
    },
  },
  {
    name: 'MD Ashadullah Ali Naim',
    id: 123,
    shift: 'Morning',
    phoneNumber: '01521514722',
    university: {
      name: 'Hajee Mohammad Danesh Science & Technology University (HSTU)',
      department: 'Physics',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2001-01-07',
    address: {
      present: 'Dinajpur',
      permanent: 'Jamalpur',
    },
    image: '/assets/avatars/md-ashadullah-ali-naim__morning__01521514722.jpg',
    links: {
      facebook: 'https://www.facebook.com/ashadullahali.naim.7?mibextid=ZbWKwL',
    },
  },
  {
    name: 'MD.SAKIBUR RASHID',
    id: 124,
    shift: 'Morning',
    phoneNumber: '01679680411',
    university: {
      name: 'State University Of Bangladesh',
      department: 'BBA (Major : Finance)',
    },
    bloodGroup: 'AB_positive',
    occupation: 'Student',
    dateOfBirth: '2001-11-12',
    address: {
      present: 'East Monirpur,Mirpur-10,Dhaka',
      permanent: 'Miapara, Jamalpur',
    },
    image: '/assets/avatars/md-sakibur-rashid__morning__01679680411.jpg',
    links: {
      facebook: 'https://www.facebook.com/sakiburrashid.sakib.5/',
    },
  },
  {
    name: 'TOKEY ISLAM',
    id: 125,
    shift: 'Morning',
    phoneNumber: '01748451815',
    university: {
      name: 'North South university (NSU)',
      department: 'BBA (Marketing)',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '1999-09-24',
    address: {
      present: 'Bashundhara Residential Area, Dhaka',
      permanent: 'Boshpara, Jamalpur',
    },
    image: '/assets/avatars/tokey-islam__morning__01748451815.jpg',
    links: {
      facebook: 'https://www.facebook.com/tokey.islam.712',
    },
  },
  {
    name: 'Md Maksudur Rahman',
    id: 126,
    shift: 'Morning',
    phoneNumber: '01629427917',
    university: {
      name: 'Jasimuddin Polytechnic Institute',
      department: 'Diploma in Engineering(civil)',
    },
    bloodGroup: 'O_positive',
    occupation: 'Civil Engineer',
    dateOfBirth: '1999-11-13',
    address: {
      present: 'Mukondo Bari Road,Jamalpur Sadar, Jamalpur',
      permanent: 'Mukondo Bari Road,Jamalpur Sadar, Jamalpur',
    },
    image: '/assets/avatars/md-maksudur-rahman__morning__01629427917.jpg',
    links: {
      facebook: 'https://www.facebook.com/maksudrahman.hridoydx?mibextid=ZbWKwL',
    },
  },
  {
    name: 'Md Arafat Ansary',
    id: 127,
    shift: 'Morning',
    phoneNumber: '01841067591',
    university: {
      name: 'M Abdur Rahim Medical College,Dinajpur',
      department: 'MBBS',
    },
    bloodGroup: 'B_positive',
    occupation: 'Student',
    dateOfBirth: '2000-01-17',
    address: {
      present: 'Dinajpur',
      permanent: 'Chamragudam more, Jamalpur',
    },
    image: '/assets/avatars/md-arafat-ansary__morning__01841067591.jpg'
    
  },
  {
    name: 'A.H.M KAMRUZZAMAN',
    id: 128,
    shift: 'Morning',
    phoneNumber: '01643071277',
    university: {
      name: 'Bangladesh University of Textiles (BUTEX)',
      department: 'Fabric Engineering',
    },
    bloodGroup: 'A_positive',
    occupation: 'Student',
    dateOfBirth: '2001-06-04',
    address: {
      present: 'Butex hall,Dhaka',
      permanent: 'Arambag,Boshpara,Jamalpur',
    },
    image: '/assets/avatars/a-h-m-kamruzzaman__morning__01643071277.jpg',
    links: {
      facebook: 'https://www.facebook.com/kamruzzaman.shagor.9?mibextid=ZbWKwL',
    },
  },
  {
    name: 'MD Sifate Rabbi Rukon',
    id: 129,
    shift: 'Day',
    phoneNumber: '01983771429',
    university: {
      name: 'Khulna University of Engineering & Technology (KUET)',
      department: 'BECM',
    },
    bloodGroup: 'O_positive',
    occupation: 'Student',
    dateOfBirth: '2001-01-14',
    address: {
      present: 'House-2, Road-3, Ali and Noor Real State, Mohammadpur, Dhaka-1207',
      permanent: 'Uttar Kacharipara, Jamalpur Sadar Jamalpur-2000',
    },
    image: '/assets/avatars/md-sifate-rabbi-rukon__day__01983771429.jpg',
    links: {
      facebook: 'https://www.facebook.com/profile.php?id=100009810676961',
      
    },
  },
];
