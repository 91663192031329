export interface IAuthorityMember {
  id: number;
  name: string;
  phone: string;
  facebook: string;
}

export const dataAuthorityMember: IAuthorityMember[] = [
  {
    id: 1,
    name: 'Ashikur Rahman Asif',
    phone: '01641145180',
    facebook: 'https://www.facebook.com/ashikurrahman1s9f',
  },
  {
    id: 2,
    name: 'Sadab Bin Rafiq Dhrubo',
    phone: '01733056599',
    facebook: 'https://www.facebook.com/sadabdhrubo',
  },
];
