import cn from 'classnames';

interface IProps {
  text: string;
  onClick?: () => void;
  className?: string;
}

export function Button({ text, className, onClick }: IProps) {
  function clickHandler() {
    onClick && onClick();
  }
  return (
    <button
      onClick={clickHandler}
      className={cn('flex items-center transition-all cursor-pointer justify-center h-10 px-5 rounded-md text-white bg-brand hover:bg-brand-deep', className)}
    >
      {text}
    </button>
  );
}
