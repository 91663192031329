import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import Logo from '../atom/logo';
import Container from '../container';
import { useState } from 'react';
import { useBreakpoint } from '../../utils/hooks/use-breakpoint';
import { Hamburger } from '../atom/icons/hamburger';
import { Cross } from '../atom/icons/cross';

const menuLink = [
  {
    id: 1,
    to: '/',
    name: 'Home',
  },
  {
    id: 2,
    to: '/gallery',
    name: 'Gallery',
  },
  {
    id: 3,
    to: '/find-friends',
    name: 'Find Friends',
  },
];

export default function Navbar() {
  const breakpoint = useBreakpoint();
  const isMobile = ['sm'].includes(breakpoint);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  return (
    <div className="fixed z-50 top-0 left-0 right-0 w-screen bg-white ">
      <Container className="flex justify-between items-center py-4 ">
        <Logo imgClassName="!w-10 md:!w-14" />
        {!isMobile ? (
          <Menus />
        ) : (
          <>
            <button className="text-brand" onClick={() => setDrawerOpen(!drawerOpen)}>
              <Hamburger className="scale-125 inline-block mr-[3px]" />
            </button>
            <SideNavDrawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />
          </>
        )}
      </Container>
    </div>
  );
}

function Menus() {
  return (
    <ul className="flex items-center gap-6 md:gap-12 font-medium">
      {menuLink?.map((m) => (
        <li className="cursor-pointer  transition-all" key={m.id}>
          <NavLink className={({ isActive }) => (isActive ? 'text-brand font-semibold' : 'text-black hover:text-brand ')} to={m.to}>
            {m.name}
          </NavLink>
        </li>
      ))}
      <li className="cursor-pointer bg-brand rounded text-white px-3 py-2  md:px-4  md:py-2.5 transition-all hover:bg-brand-deep">
        Enlist Info
      </li>
    </ul>
  );
}

interface ISideNavDrawerProps {
  open: boolean;
  setDrawerOpen: (v: boolean) => void;
}

function SideNavDrawer({ open, setDrawerOpen }: ISideNavDrawerProps) {
  return (
    <div
      className={cn('fixed z-50 p-10 w-screen h-screen bg-white transition-all top-0 bottom-0', {
        'left-0': open,
        '-left-full': !open,
      })}
    >
      <div className="flex justify-end mb-6 text-brand" onClick={() => setDrawerOpen(false)}>
        <Cross className="scale-150" />
      </div>
      <ul className="space-y-3">
        {menuLink?.map((m) => (
          <li className="cursor-pointer  transition-all" key={m.id}>
            <NavLink className={({ isActive }) => (isActive ? 'text-brand font-semibold' : 'text-black hover:text-brand ')} to={m.to}>
              {m.name}
            </NavLink>
          </li>
        ))}
        <li className="cursor-pointer inline-flex !mt-4 bg-brand rounded text-white px-3 py-2  md:px-4  md:py-2.5 transition-all hover:bg-brand-deep">
          Enlist Info
        </li>
      </ul>
    </div>
  );
}
