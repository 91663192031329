import Heading from '../component/atom/heading';
import Layout from '../component/layout';
import FindFriendsSearchForm from '../component/organisms/find-friends-search-form';
import SearchResult from '../component/organisms/search-result';

export default function FindFriends() {
  return (
    <Layout>
      <div>
        <Heading text="Find Your Friends" />
        <FindFriendsSearchForm />
        <SearchResult />
      </div>
    </Layout>
  );
}
