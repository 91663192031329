import { ReactNode } from 'react';
import Footer from './organisms/footer/footer';
import Navbar from './organisms/navbar';

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <Navbar />
      <div className="min-h-[calc(50vh_-_62px)] mt-[92px]">{children}</div>
      <Footer />
    </>
  );
}
