import cn from 'classnames';
import { Search } from '../atom/icons/search';

interface IProps {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (v: string) => void;
}

export function Input({ value, placeholder, className, onChange }: IProps) {
  function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e.target.value);
  }

  return (
    <div className={cn('flex items-center relative h-10', className)}>
      <span className="absolute top-2.5 left-2 text-gray-500">
        <Search />
      </span>
      <input
        value={value}
        type="text"
        className="rounded-md block w-full h-full border transition-all focus:outline-0 focus:border-brand p-1.5 pl-8"
        placeholder={placeholder}
        onChange={changeHandler}
      />
    </div>
  );
}
