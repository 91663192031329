import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ISingleData } from '../../data/data';
import { searchFriendsHandler } from '../../static/utils';
import { Button } from '../atom/button';

import Container from '../container';
import SingleFriend from './single-friend';

export default function SearchResult() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState<ISingleData[] | []>([]);
  const [loaded, setLoaded] = useState<number>(0);

  useEffect(() => {
    const res = searchFriendsHandler({
      name: searchParams.get('name') || '',
      university: searchParams.get('university') || '',
      bloodGroup: searchParams.get('bloodGroup') || '',
      shift: searchParams.get('shift') || '',
    });
    setResult(res);
  }, [location.search, searchParams]);

  return (
    <Container>
      <div className="grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 grid mt-5 md:mt-16 xl:mt-24">
        {result.length ? (
          [...result].slice(0, loaded + 9).map((item: ISingleData) => <SingleFriend data={item} key={item.id} />)
        ) : (
          <div className="flex justify-center col-span-full md:text-xl xl:text-2xl py-4 font-medium text-brand">No result found</div>
        )}
      </div>
      {result.length >= 9 && loaded !== result.length && (
        <div className="mt-12 flex justify-center">
          <Button onClick={() => setLoaded(loaded + (result.length - loaded < 9 ? result.length - loaded : 9))} text="See More" />
        </div>
      )}
    </Container>
  );
}
