import cn from 'classnames';
import { useState } from 'react';
import { ISingleData } from '../../data/data';
import { renderBloodGroup } from '../../static/utils';
import { Button } from '../atom/button';
import FriendDetailsModal from './modals/friend-details-modal';
export default function SingleFriend({ data }: { data: ISingleData }) {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  return (
    <div className="border rounded-md p-3 pb-5">
      <div className="flex justify-center mb-6">
        <img src={data.image} className="w-1/2 aspect-square object-cover rounded-md" alt="jzs-16" />
      </div>
      <div className="space-y-3 mb-5">
        <FriendSingleInfo label="Name" value={data.name} highlightValue />
        <FriendSingleInfo label="Blood Group" value={renderBloodGroup(data.bloodGroup)} />
        <FriendSingleInfo label="Shift" value={data.shift} />
        <FriendSingleInfo label="Contact" value={data.phoneNumber.toLocaleString()} tel />
      </div>
      <Button text="View Details" onClick={() => setOpenDetailsModal(true)} />
      <FriendDetailsModal data={data} open={openDetailsModal} closeModal={() => setOpenDetailsModal(false)} />
    </div>
  );
}

export function FriendSingleInfo({
  label,
  value,
  highlightValue,
  tel,
  className,
  component,
}: {
  label: string;
  value?: string;
  highlightValue?: boolean;
  tel?: boolean;
  className?: string;
  component?: JSX.Element;
}) {
  const ValueComponent = tel ? (
    <a href={`tel:${value}`}>{value}</a>
  ) : (
    <p className={cn({ 'lg:text-xl font-semibold !text-brand capitalize': highlightValue })}>{value}</p>
  );
  return (
    <div className={cn(className)}>
      <p className={cn('text-xs lg:text-sm text-gray-400 capitalize')}>{label}</p>
      {component || ValueComponent || <></>}
    </div>
  );
}
