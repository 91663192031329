import cn from 'classnames';
import { NavLink } from 'react-router-dom';

export default function Logo({ className, imgClassName }: { className?: string; imgClassName?: string }) {
  return (
    <NavLink
      to="/"
      className={({ isActive }) =>
        cn(className, {
          active: isActive,
        })
      }
    >
      <img src="/assets/vector/logo.svg" alt="logo" className={cn('w-14 h-auto cursor-pointer', imgClassName)} />
    </NavLink>
  );
}
