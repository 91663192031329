export function Facebook(props: React.SVGAttributes<{}>) {
  return (
    <svg {...props} width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.15518 18.0054V10.0718H0.485352V6.97992H3.15518V4.69974C3.15518 2.0536 4.77135 0.612732 7.13189 0.612732C8.26261 0.612732 9.23441 0.696917 9.51761 0.734544V3.49991L7.88045 3.50065C6.59666 3.50065 6.34809 4.11069 6.34809 5.00588V6.97992H9.40981L9.01116 10.0718H6.34809V18.0054H3.15518Z"
        fill="currentColor"
      />
    </svg>
  );
}
