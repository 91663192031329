import Heading from '../component/atom/heading';
import Container from '../component/container';
import Layout from '../component/layout';

const images = [
  '/assets/gallery/1.jpg',
  '/assets/gallery/2.jpg',
  '/assets/gallery/3.jpg',
  '/assets/gallery/4.jpg',
  '/assets/gallery/5.jpg',
  '/assets/gallery/6.jpg',
  '/assets/gallery/7.jpg',
  '/assets/gallery/8.jpg',
  '/assets/gallery/9.jpg',
  '/assets/gallery/10.jpg',
];

export default function Gallery() {
  return (
    <Layout>
      <Container>
        <Heading text="Our Gallery" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {images?.map((item, key) => (
            <img key={key} src={item} className="rounded border border-brand/30 aspect-video object-cover" alt="jzs-batch-16" />
          ))}
        </div>
      </Container>
    </Layout>
  );
}
