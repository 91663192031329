import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ISingleData } from '../../../data/data';
import { renderBloodGroup } from '../../../static/utils';
import { Cross } from '../../atom/icons/cross';
import { FacebookOriginal } from '../../atom/icons/facebook-original';
import { LinkedinOriginal } from '../../atom/icons/linkedin-original';
import { FriendSingleInfo } from '../single-friend';

interface IProps {
  open: boolean;
  closeModal: () => void;
  data: ISingleData;
}

export default function FriendDetailsModal({ open, closeModal, data }: IProps) {
  function handleCloseModal() {
    closeModal();
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={handleCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-brand bg-opacity-25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="mb-6 flex justify-between">
                    <img src={data.image} className="w-1/2 aspect-square object-cover rounded-md" alt="" />
                    <div>
                      <span
                        onClick={closeModal}
                        className="bg-brand/10 p-3 rounded-full inline-flex text-sm font-medium text-brand hover:bg-brand cursor-pointer hover:text-white transition-all"
                      >
                        <Cross />
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-3 gap-y-4">
                    <FriendSingleInfo className="col-span-full" label="Name" value={data.name} highlightValue />
                    <FriendSingleInfo className="col-span-1" label="Shift" value={data.shift} />
                    <FriendSingleInfo className="col-span-1" label="Blood Group" value={renderBloodGroup(data.bloodGroup)} />
                    {!!data?.university && (
                      <>
                        {!!data?.university.name && (
                          <FriendSingleInfo className="col-span-full" label="University" value={data.university.name} />
                        )}
                        {!!data?.university?.department && (
                          <FriendSingleInfo className="col-span-full" label="Department" value={data.university.department} />
                        )}
                      </>
                    )}
                    {!!data?.links && (
                      <FriendSingleInfo
                        className="col-span-1"
                        label="Social Links"
                        component={<SocialLinks facebook={data?.links?.facebook} linkedin={data?.links?.linkedin} />}
                      />
                    )}
                    <FriendSingleInfo className="col-span-1" label="Occupation" value={data.occupation} />
                    <FriendSingleInfo className="col-span-1" label="Date Of Birth" value={data.dateOfBirth} />
                    <FriendSingleInfo className="col-span-1" label="Phone" value={data.phoneNumber.toString()} tel />
                    <FriendSingleInfo className="col-span-full" label="Present Address" value={data.address.present} />
                    <FriendSingleInfo className="col-span-full" label="Permanent Address" value={data.address.permanent} />
                  </div>
                  <div className="mt-8 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-brand/10 px-4 py-2 text-sm font-medium text-brand hover:bg-brand hover:text-white transition-all"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function SocialLinks({ facebook, linkedin }: { facebook?: string; linkedin?: string }) {
  return (
    <div className="flex gap-2 mt-1">
      {!!facebook && (
        <a href={facebook} target="_blank" rel="noreferrer">
          <FacebookOriginal />
        </a>
      )}
      {!!linkedin && (
        <a href={linkedin} target="_blank" rel="noreferrer">
          <LinkedinOriginal />
        </a>
      )}
    </div>
  );
}

// name,university,department,address ->col-span-full
// shift,phoneNumber,bloodGroup,occupation,dateOfBirth,Links ->col-span-1

// consistency
/*
image
name
shift,bloodGroup
university
department,link
occupation,dateOfBirth,
phone
address->present,permanent


*/
