import { IAuthorityMember } from '../data/autority-member';
import { Facebook } from './atom/icons/facebook';

export default function AuthorityMember({ data }: { data: IAuthorityMember }) {
  return (
    <div>
      <h3 className="mb-1 text-gray-900">{data.name}</h3>
      <a href={`tel:${data.phone}`} className="mb-4 text-gray-500 inline-flex transition-all hover:text-gray-900">
        {data.phone}
      </a>
      <a
        href={data.facebook}
        target="_blank"
        rel="noreferrer"
        className="w-7 h-7 flex items-center justify-center rounded-full border border-gray-300 hover:border-brand transition-all text-gray-800 hover:text-white hover:bg-brand bg-brand/0"
      >
        <Facebook className="!h-3 !w-auto" />
      </a>
    </div>
  );
}
