import { allData, ISingleData } from '../data/data';

export const googleFormLink = { morning: 'https://www.google.com', day: 'https://www.google.com' };

export function renderBloodGroup(v: string) {
  const result = v.split('_');
  const first = result[0];
  const last = result[1] === 'positive' ? '+' : '-';
  return `${first}${last}`;
}

interface ISearchFriendHandler {
  name: string;
  university: string;
  bloodGroup: string;
  shift: string;
}

export function searchFriendsHandler({ name, university, bloodGroup, shift }: ISearchFriendHandler): ISingleData[] {
  let all = [...allData];

  if (name) {
    all = all.filter((item: ISingleData) => item.name.toLowerCase().trim().includes(name.toLowerCase().trim()));
  }
  if (university) {
    all = all.filter(
      (item: ISingleData) => item?.university?.name && item?.university?.name.toLowerCase().trim() === university.toLowerCase().trim()
    );
  }
  if (bloodGroup) {
    all = all.filter((item: ISingleData) => item.bloodGroup.toLowerCase().trim().includes(bloodGroup.toLowerCase().trim()));
  }
  if (shift) {
    all = all.filter((item: ISingleData) => item.shift.toLowerCase().trim().includes(shift.toLowerCase().trim()));
  }
  return all;
}
