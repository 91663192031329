export function Hamburger(props: React.SVGAttributes<{}>) {
  return (
    <svg {...props} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.75C0 0.335786 0.335786 0 0.75 0H15.25C15.6642 0 16 0.335786 16 0.75C16 1.16421 15.6642 1.5 15.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75ZM0 4.91667C0 4.50245 0.335786 4.16667 0.75 4.16667H15.25C15.6642 4.16667 16 4.50245 16 4.91667C16 5.33088 15.6642 5.66667 15.25 5.66667H0.75C0.335786 5.66667 0 5.33088 0 4.91667ZM0 9.08333C0 8.66912 0.335786 8.33333 0.75 8.33333H15.25C15.6642 8.33333 16 8.66912 16 9.08333C16 9.49755 15.6642 9.83333 15.25 9.83333H0.75C0.335786 9.83333 0 9.49755 0 9.08333ZM0 13.25C0 12.8358 0.335786 12.5 0.75 12.5H15.25C15.6642 12.5 16 12.8358 16 13.25C16 13.6642 15.6642 14 15.25 14H0.75C0.335786 14 0 13.6642 0 13.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
