import Slider from 'react-slick';
export default function Hero() {
  const images: string[] = ['/assets/gallery/1.jpg', '/assets/gallery/2.jpg', '/assets/gallery/4.jpg'];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <Slider {...settings}>
        {images.map((item, idx: number) => (
          <div className="bg-gray-300 w-full h-auto" key={'hero-slider-' + idx}>
            <img src={item} alt="jzs-16" className="w-full h-auto aspect-[1519/557] object-cover" />
          </div>
        ))}
      </Slider>
    </div>
  );
}
