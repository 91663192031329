export const optionsBloodGroup = [
  { id: 1, name: 'A+', value: 'A_positive' },
  { id: 2, name: 'A-', value: 'A_negative' },
  { id: 3, name: 'B+', value: 'B_positive' },
  { id: 4, name: 'B-', value: 'B_negative' },
  { id: 5, name: 'AB+', value: 'AB_positive' },
  { id: 6, name: 'AB-', value: 'AB_negative' },
  { id: 7, name: 'O+', value: 'O_positive' },
  { id: 8, name: 'O-', value: 'O_negative' },
];

export const optionsShift = [
  { id: 1, name: 'Morning', value: 'Morning' },
  { id: 2, name: 'Day', value: 'Day' },
];

export const optionsUniversity = [
  { id: 1, name: 'Institute of Science Trade & Technology (ISTT)', value: 'Institute of Science Trade & Technology (ISTT)' },
  { id: 2, name: 'Brac University ', value: 'Brac University ' },
  { id: 3, name: 'Habibullah Bahar University College ', value: 'Habibullah Bahar University College ' },
  { id: 4, name: 'Stamford University Bangladesh ', value: 'Stamford University Bangladesh' },
  {
    id: 5,
    name: 'Hajee Mohammad Danesh Science & Technology University (HSTU) ',
    value: 'Hajee Mohammad Danesh Science & Technology University (HSTU) ',
  },
  { id: 6, name: 'North South university (NSU) ', value: 'North South university (NSU) ' },
  { id: 7, name: 'Jahangirnagar University (JU) ', value: 'Jahangirnagar University (JU) ' },
  { id: 8, name: 'American International University Bangladesh (AIUB) ', value: 'American International University Bangladesh (AIUB) ' },
  { id: 9, name: 'University of Dhaka (DU) ', value: 'University of Dhaka (DU) ' },
  { id: 10, name: 'University of Asia Pacific (UAP) ', value: 'University of Asia Pacific (UAP) ' },
  { id: 11, name: 'Independent University Bangladesh (IUB) ', value: 'Independent University Bangladesh (IUB) ' },
  { id: 12, name: 'Khulna University of Engineering & Technology (KUET) ', value: 'Khulna University of Engineering & Technology (KUET) ' },
  { id: 13, name: 'Patuakhali Science and Technology University (PSTU) ', value: 'Patuakhali Science and Technology University (PSTU) ' },
  { id: 14, name: 'Bangladesh University of Textiles (BUTEX) ', value: 'Bangladesh University of Textiles (BUTEX) ' },
  { id: 15, name: 'Sylhet Polytechnic Institute (SPI) ', value: 'Sylhet Polytechnic Institute (SPI) ' },
  { id: 16, name: 'Green University (GU) ', value: 'Green University (GU) ' },
  {
    id: 17,
    name: 'National Institute of Textile Engineering and Research (NITER)',
    value: 'National Institute of Textile Engineering and Research (NITER)',
  },
  { id: 18, name: 'Mymensingh Medical College', value: 'Mymensingh Medical College' },
  { id: 19, name: 'Daffodil international University (DIU) ', value: 'Daffodil international University (DIU) ' },
  {
    id: 20,
    name: 'Bangladesh University of Business and technology (BUBT) ',
    value: 'Bangladesh University of Business and technology (BUBT) ',
  },
  {
    id: 21,
    name: 'Bangladesh University of Engineering and Technology (BUET) ',
    value: 'Bangladesh University of Engineering and Technology (BUET) ',
  },
  { id: 22, name: 'East West University (EWU) ', value: 'East West University (EWU) ' },
  { id: 23, name: 'Dhaka College (DC) ', value: 'Dhaka College (DC) ' },
  { id: 24, name: 'Govt. Ashek Mahmud College University', value: 'Govt. Ashek Mahmud College University' },
  { id: 25, name: 'University of Chittagong (CU) ', value: 'University of Chittagong (CU) ' },
  { id: 26, name: 'Chittagong Medical College (CMC) ', value: 'Chittagong Medical College (CMC) ' },
  { id: 27, name: 'Government Titumir College ', value: 'Government Titumir College ' },
  {
    id: 28,
    name: 'Mawlana Bhashani Science and Technology University (MBSTU) ',
    value: 'Mawlana Bhashani Science and Technology University (MBSTU) ',
  },
  {
    id: 29,
    name: 'Sylhet Agricultural University (SAU) ',
    value: 'Sylhet Agricultural University (SAU) ',
  },
  { id: 30, name: 'Sheikh kamal Textile Engineering College, Jhenaidah ', value: 'Sheikh kamal Textile Engineering College, Jhenaidah ' },
  { id: 31, name: 'United International University (UIU) ', value: 'United International University (UIU) ' },
  { id: 32, name: 'BGMEA University of Fashion & Technology (BUFT) ', value: 'BGMEA University of Fashion & Technology (BUFT) ' },
  {
    id: 33,
    name: 'Rajshahi University of Engineering & Technology (RUET) ',
    value: 'Rajshahi University of Engineering & Technology (RUET) ',
  },
  { id: 34, name: 'Jagannath University (JnU) ', value: 'Jagannath University (JnU) ' },
  { id: 35, name: 'Textile Institute Tangail ', value: 'Textile Institute Tangail ' },
  { id: 36, name: 'Sherpur Polytechnic Institute ', value: 'Sherpur Polytechnic Institute ' },
  { id: 37, name: 'Bangladesh University of Professionals (BUP)', value: 'Bangladesh University of Professionals (BUP)' },
  { id: 38, name: 'Mohammodpur Kendriya Collge Dhaka', value: 'Mohammodpur Kendriya Collge Dhaka' },
  { id: 39, name: 'Islamic University (IU)', value: 'Islamic University (IU)' },
  { id: 40, name: 'Dhaka International University', value: 'Dhaka International University' },
  { id: 41, name: 'Leading University ', value: 'Leading University ' },
  { id: 42, name: 'Barishal University (BU) ', value: 'Barishal University (BU) ' },
  { id: 43, name: 'Shanto-mariam University of Creative Technology ', value: 'Shanto-mariam University of Creative Technology ' },
  { id: 44, name: 'Mymensingh Polytechnic Institute ', value: 'Mymensingh Polytechnic Institute ' },
  { id: 45, name: 'Bangladesh University', value: 'Bangladesh University' },
  { id: 46, name: 'Southeast University ', value: 'Southeast University ' },
  { id: 47, name: 'Graphic Arts Institute', value: 'Graphic Arts Institute' },
  { id: 48, name: 'Jatiya Kabi Kazi Nazrul Islam University (JKKNIU) ', value: 'Jatiya Kabi Kazi Nazrul Islam University (JKKNIU) ' },
  { id: 49, name: 'KIIT University, Odisha, India ', value: 'KIIT University, Odisha, India ' },
  { id: 50, name: 'Nandina shekh anowar hossain college', value: 'Nandina shekh anowar hossain college' },
  { id: 51, name: 'Syed Nazrul Islam Medical College,Kishoreganj ', value: 'Syed Nazrul Islam Medical College,Kishoreganj ' },
  {
    id: 52,
    name: 'Shahjalal University of Science and Technology (SUST) ',
    value: 'Shahjalal University of Science and Technology (SUST) ',
  },
  { id: 53, name: 'Sylhet MAG Osmani Medical College ', value: 'Sylhet MAG Osmani Medical College ' },
  { id: 54, name: 'Dr. M A Wazed Miah Textile Engineering College', value: 'Dr. M A Wazed Miah Textile Engineering College' },
  { id: 55, name: 'University Putra Malaysia', value: 'University Putra Malaysia' },
  {
    id: 56,
    name: 'Ahsanullah University of Science and Technology (AUST) ',
    value: 'Ahsanullah University of Science and Technology (AUST) ',
  },
  { id: 57, name: 'Khulna University (KU) ', value: 'Khulna University (KU) ' },
  { id: 58, name: 'University of Liberal Arts Bangladesh (ULAB) ', value: 'University of Liberal Arts Bangladesh (ULAB) ' },
  { id: 59, name: 'Shorishabari govt College,Jamalpur', value: 'Shorishabari govt College,Jamalpur' },
  { id: 60, name: 'National University', value: 'National University' },
  {
    id: 61,
    name: 'Bangamata Sheikh Fojilatunnesa Mujib Science & Technology University ',
    value: 'Bangamata Sheikh Fojilatunnesa Mujib Science & Technology University ',
  },
  { id: 62, name: 'State University Of Bangladesh', value: 'State University Of Bangladesh' },
  { id: 63, name: 'Govt. Unani & Ayurvedic Medical College ', value: 'Govt. Unani & Ayurvedic Medical College ' },
  {
    id: 64,
    name: 'Chittagong University of Engineering & Technology (CUET) ',
    value: 'Chittagong University of Engineering & Technology (CUET) ',
  },
  { id: 65, name: 'Rangpur medical college ', value: 'Rangpur medical college ' },
  { id: 66, name: 'Sheikh Fazilatunnesa Mujib University, Jamalpur ', value: 'Sheikh Fazilatunnesa Mujib University, Jamalpur ' },
  { id: 67, name: 'Tejgaon College', value: 'Tejgaon College' },
  { id: 68, name: 'Ananda Mohon College Mymensingh', value: 'Ananda Mohon College Mymensingh' },
  { id: 69, name: 'Sheikh Hasina Medical College,Tangail ', value: 'Sheikh Hasina Medical College,Tangail ' },
  { id: 70, name: 'Noakhali Science & Technology University (NSTU) ', value: 'Noakhali Science & Technology University (NSTU) ' },
  { id: 71, name: 'Govt. Shaheed Suhrawardy College, Dhaka', value: 'Govt. Shaheed Suhrawardy College, Dhaka' },
  { id: 72, name: 'City University ', value: 'City University' },
  { id: 73, name: 'Primeasia University', value: 'Primeasia University' },
  {
    id: 74,
    name: 'Institute of Business Administration, Jahangirnagar University (JU)',
    value: 'Institute of Business Administration, Jahangirnagar University (JU)',
  },
  { id: 75, name: 'Govt Islampur College ', value: 'Govt Islampur College ' },
  { id: 76, name: 'Daffodil Technical Institute (DTI)', value: 'Daffodil Technical Institute (DTI)' },
  { id: 77, name: 'University of Rajshahi', value: 'University of Rajshahi' },
  {
    id: 78,
    name: 'Bangabandhu Sheikh Mujibur Rahman Science and Technology University (BSMRSTU)',
    value: 'Bangabandhu Sheikh Mujibur Rahman Science and Technology University (BSMRSTU)',
  },
  { id: 79, name: 'Dhaka Polytechnic Institute ', value: 'Dhaka Polytechnic Institute ' },
  { id: 80, name: 'Jasimuddin Polytechnic Institute', value: 'Jasimuddin Polytechnic Institute'},
  { id: 81, name: 'M Abdur Rahim Medical College,Dinajpur', value: 'M Abdur Rahim Medical College,Dinajpur'},
];

/*









*/
