import Copyright from '../../atom/copyright';
import AuthorityMember from '../../authority-member';
import Container from '../../container';
import { Facebook } from '../../atom/icons/facebook';
import { dataAuthorityMember } from '../../../data/autority-member';

export default function Footer() {
  return (
    <footer className="mt-20 bg-brand/[5%] pt-5 md:pt-10">
      <Container className="flex flex-col gap-20 xl:flex-row xl:gap-0 justify-between mb-10">
        <div>
          <h2 className="text-brand font-semibold text-xl mb-4">JZS - 16</h2>
          <p className="max-w-[30ch] leading-6 text-sm mb-6 text-gray-600">
            We are here to help each other. We create bonding with love and friendship.
          </p>
          <a
            href="https://www.facebook.com/jzc.batch16"
            target="_blank"
            rel="noreferrer"
            className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 hover:border-brand transition-all text-gray-800 hover:text-white hover:bg-brand bg-brand/0"
          >
            <Facebook />
          </a>
        </div>
        <div>
          <h3 className="text-brand uppercase font-semibold text-xl mb-3.5">Contact</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-24 gap-y-12">
            {!!dataAuthorityMember && dataAuthorityMember.map((item) => <AuthorityMember data={item} key={item.id} />)}
          </div>
        </div>
      </Container>
      <Copyright />
    </footer>
  );
}
