import { useState } from 'react';
import { optionsBloodGroup, optionsShift, optionsUniversity } from '../../static/options';
import { Button } from '../atom/button';
import Container from '../container';
import { Input } from '../molecules/input';
import ListBox from '../molecules/listbox';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { IListBoxOption } from '../../type';
// import { useParams } from '../../static/urls';

export default function FindFriendsSearchForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  // const params = useParams();
  const [name, setName] = useState<string>('');
  const [university, setUniversity] = useState<string>('');
  const [bloodGroup, setBloodGroup] = useState<string>('');
  const [shift, setShift] = useState<string>('');
  const [selectedUniversity, setSelectedUniversity] = useState<IListBoxOption | null>(null);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState<IListBoxOption | null>(null);
  const [selectedShift, setSelectedShift] = useState<IListBoxOption | null>(null);

  function clickHandler() {
    if (location.pathname !== '/find-friends') {
      navigate(`/find-friends?name=${name}&university=${university}&bloodGroup=${bloodGroup}&shift=${shift}`);
    } else {
      setSearchParams({ name, university, bloodGroup, shift });
    }
  }

  function clearHandler() {
    setName('');
    setUniversity('');
    setBloodGroup('');
    setShift('');
    setSelectedUniversity(null);
    setSelectedBloodGroup(null);
    setSelectedShift(null);
    navigate(location.pathname);
  }

  return (
    <div>
      <Container className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-7 gap-4">
        <Input value={name} onChange={(v: string) => setName(v)} placeholder="Search By Name" className="col-span-1 xl:col-span-2" />
        <ListBox
          options={optionsUniversity}
          onChange={(v: string) => setUniversity(v)}
          placeholder="University"
          optionSearch={true}
          optionSearchInputPlaceholder="Search University"
          className="col-span-1 xl:col-span-2"
          selected={selectedUniversity}
          setSelected={setSelectedUniversity}
        />
        <ListBox
          selected={selectedBloodGroup}
          setSelected={setSelectedBloodGroup}
          onChange={(v: string) => setBloodGroup(v)}
          options={optionsBloodGroup}
          placeholder="Blood Group"
        />
        <ListBox
          selected={selectedShift}
          setSelected={setSelectedShift}
          options={optionsShift}
          placeholder="Shift"
          onChange={(v: string) => setShift(v)}
        />
        <div className="md:flex flex-wrap xl:flex-nowrap xl:items-center col-span-full md:col-span-1">
          {(location.search || name || university || bloodGroup || shift || selectedUniversity || selectedBloodGroup || selectedShift) && (
            <div className="w-full xl:w-[unset] xl:mt-2 xl:mr-2.5">
              <span onClick={clearHandler} className="mb-2 text-sm inline-block cursor-pointer">
                Clear
              </span>
            </div>
          )}
          <Button text="Find" className="px-20 xl:px-[unset] w-full" onClick={clickHandler} />
        </div>
      </Container>
    </div>
  );
}
