export function DownArrow(props: React.SVGAttributes<{}>) {
  return (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26277 7.20711C6.87225 7.59763 6.23908 7.59763 5.84856 7.20711L0.848557 2.20711C0.458033 1.81658 0.458033 1.18342 0.848557 0.792893C1.23908 0.402369 1.87225 0.402369 2.26277 0.792893L6.55566 5.08579L10.8486 0.792893C11.2391 0.402369 11.8722 0.402369 12.2628 0.792893C12.6533 1.18342 12.6533 1.81658 12.2628 2.20711L7.26277 7.20711Z"
        fill="currentColor"
      />
    </svg>
  );
}
