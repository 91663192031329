import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home';
import NotFound from './pages/404';
import { useEffect } from 'react';
import Gallery from './pages/gallery';
import FindFriends from './pages/find-friends';

export default function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="overflow-x-hidden">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/find-friends" element={<FindFriends />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
